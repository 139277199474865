import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { showNotification } from "../../../../components/customHooks/useAxiosPost";
import moment from "moment";
import axios from "axios";

import CircularLoader from "../../../../components/reusable/Loader";

import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBTabControl from "../../../../components/design-system/TabControl/TFBTabControl";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import TFBDatePicker from "../../../../components/design-system/DatePicker/TFBDatePicker";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";
import Swal from "sweetalert2";
import TFBColorPicker from "../../../../components/design-system/TFBColorPicker/TFBColorPicker";

const EditTeam = (props) => {
  const {
    languagePack,
    closePopup,
    isOpenPopup,
    idTeam,
    refreshTeams,
    players,
  } = props;

  // ################ TAB 1 STATE #######################

  const [isLoading, setIsLoading] = useState(true);
  const [teamName, setTeamName] = useState("");
  const [teamType, setTeamType] = useState(null);
  const [teamTypes, setTeamTypes] = useState([]);
  const [coach, setCoach] = useState(null);
  const [coaches, setCoaches] = useState([]);
  const [activeSeason, setActiveSeason] = useState(null);
  const [teamColor, setTeamColor] = useState(null);

  // ################ TAB 2 STATE #######################
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isActiveSeason, setIsActiveSeason] = useState(false);

  const [addNewSeason, setAddNewSeason] = useState(false);
  const [newSeasonStartDate, setNewSeasonStartDate] = useState(null);
  const [newSeasonEndDate, setNewSeasonEndDate] = useState(null);
  const [newSeasonIsActiveSeason, setNewSeasonIsActiveSeason] = useState(false);
  const [migrateAll, setMigrateAll] = useState(true);
  const [playersToMigrate, setPlayersToMigrate] = useState(
    [...players].map((p) => ({ ...p, checked: true }))
  );
  const [emptyDate, setEmptyDate] = useState(false);
  const [emptyDateUpdate, setEmptyDateUpdate] = useState(false);

  // ################ TAB 3 STATE #######################

  const [rpeFromApp, setRpeFromApp] = useState(false);

  // ################ CURRENT ACTIVE SEASON (FORBIDDEN SET INACTIVE/DELETE) #######################
  const [currentActiveSeasonId, setCurrentActiveSeasonId] = useState(null);

  const [openDeleteMessage, setOpenDeleteMessage] = useState(false);

  useEffect(() => {
    if (isLoading) {
      getTeamDetails();
    }
  }, [isLoading]);

  const getTeamDetails = () => {
    axios
      .get(
        `club/get_team_details?id_club=${props.currentUser.id_club}&id_team=${idTeam}`
      )
      .then((res) => {
        if (res.data?.success == 1) {
          //TAB 1 STATE
          setTeamName(res.data.team_name);
          setTeamType(res.data.type);
          setCoach(res.data.coach);
          setActiveSeason(res.data.active_season);
          setTeamTypes(res.data.team_types);
          setCoaches(res.data.coaches);
          setSeasons(res.data.seasons);
          setIsLoading(false);
          setCurrentActiveSeasonId(res.data.active_season?.value ?? null);
          setTeamColor(res.data.team_color ?? null);

          //TAB 2 STATE
          setSelectedSeason(res.data.active_season);
          setStartDate(res.data.active_season?.start ?? null);
          setEndDate(res.data.active_season?.end ?? null);
          setIsActiveSeason(
            res.data.active_season?.active == "1" ? true : false
          );
          //TAB 3 STATE
          setRpeFromApp(
            Number(res.data.advanced_settings?.input_rpe_before_training) === 0
              ? false
              : true
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const openAddNewSeason = () => {
    setAddNewSeason(true);
  };

  const closeAddNewSeason = () => {
    setNewSeasonStartDate(null);
    setNewSeasonEndDate(null);
    setNewSeasonIsActiveSeason(false);
    setAddNewSeason(false);
    setMigrateAll(true);
    setPlayersToMigrate(playersToMigrate.map((p) => ({ ...p, checked: true })));
  };

  const handleChangeSelectedSeason = (option) => {
    if (option != null) {
      setSelectedSeason(option);
      setStartDate(option?.start ?? null);
      setEndDate(option?.end ?? null);
      setIsActiveSeason(option?.active == "1" ? true : false);
    }
  };

  const handleAlertSeasonDate = (isNewSeason) => {
    Swal.fire({
      title: languagePack.too_short_season_error,
      showDenyButton: true,
      confirmButtonText: languagePack.yes,
      denyButtonText: languagePack.no,
      customClass: {
        container: "custom-swals-container-add-team",
        confirmButton: "custom-swals-confirm-button",
        denyButton: "custom-swals-deny-button",
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isDenied) {
        isNewSeason ? setNewSeasonEndDate(null) : setEndDate(null);
      } else if (result.isConfirmed && !isNewSeason) {
        postUpdatedSeason();
      } else if (result.isConfirmed && isNewSeason) {
        postNewSeasons();
      }
    });
  };

  const openDeleteMessagePopup = () => {
    setOpenDeleteMessage(true);
  };

  const closeDeleteMessagePopup = () => {
    setOpenDeleteMessage(false);
  };

  const handleCheckbox = (index) => {
    const array = [...playersToMigrate];
    playersToMigrate[index]["checked"] = !playersToMigrate[index]["checked"];

    setPlayersToMigrate(array);
    setMigrateAll(array.filter((p) => !p.checked).length > 0 ? false : true);
  };

  const saveTeamData = () => {
    const payload = {
      id_team: idTeam,
      team_name: teamName,
      team_type: teamType?.value ?? null,
      coach: coach?.value ?? null,
      active_season: activeSeason?.value ?? null,
      team_color: teamColor,
      input_rpe_before_training: rpeFromApp ? 1 : 0,
    };

    axios
      .post(`club/save_team_details`, payload)
      .then((res) => {
        if (res.data?.success == 1) {
          setIsLoading(true);
          refreshTeams();
          showNotification(
            "success",
            languagePack.teams,
            languagePack.success_save_team_details
          );
        } else {
          showNotification(
            "danger",
            languagePack.teams,
            languagePack.fail_save_team_details
          );
        }
        closePopup();
      })
      .catch((err) => console.log(err));
  };

  const updateSeason = () => {
    if (startDate == null || endDate == null) {
      setEmptyDateUpdate(true);
      return;
    }

    if (
      endDate &&
      startDate &&
      !(moment(endDate) < moment(startDate)) &&
      moment(endDate).diff(moment(startDate), "days") <= 30
    ) {
      handleAlertSeasonDate(false);
      return;
    }

    postUpdatedSeason();
  };

  const postUpdatedSeason = () => {
    const payload = {
      id_team: idTeam,
      id_season: selectedSeason?.value ?? null,
      season_start: startDate,
      season_end: endDate,
      active: isActiveSeason ? 1 : 0,
    };

    axios
      .post(`club/update_season`, payload)
      .then((res) => {
        if (res.data?.success == 1) {
          setIsLoading(true);
          refreshTeams();
          showNotification(
            "success",
            languagePack.teams,
            languagePack.success_update_season
          );
        } else {
          showNotification(
            "danger",
            languagePack.teams,
            languagePack.fail_update_season
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteSeason = () => {
    const payload = {
      id_team: idTeam,
      id_season: selectedSeason?.value ?? null,
    };

    axios
      .post(`club/delete_season`, payload)
      .then((res) => {
        if (res.data?.success == 1) {
          setIsLoading(true);
          closeDeleteMessagePopup();
          showNotification(
            "success",
            languagePack.teams,
            languagePack.success_delete_season
          );
        } else {
          showNotification(
            "danger",
            languagePack.teams,
            languagePack.fail_delete_season
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const addSeason = () => {
    if (newSeasonEndDate == null || newSeasonStartDate == null) {
      setEmptyDate(true);
      return;
    }

    if (
      newSeasonEndDate &&
      newSeasonStartDate &&
      !(moment(newSeasonEndDate) < moment(newSeasonStartDate)) &&
      moment(newSeasonEndDate).diff(moment(newSeasonStartDate), "days") <= 30
    ) {
      handleAlertSeasonDate(true);
      return;
    }

    postNewSeasons();
  };

  const postNewSeasons = () => {
    const payload = {
      id_team: idTeam,
      season_start: newSeasonStartDate,
      season_end: newSeasonEndDate,
      active: newSeasonIsActiveSeason ? 1 : 0,
      players_to_migrate: playersToMigrate.filter((p) => p.checked),
    };

    axios
      .post(`club/add_season`, payload)
      .then((res) => {
        if (res.data?.success == 1) {
          setIsLoading(true);
          closeAddNewSeason();
          refreshTeams();
          showNotification(
            "success",
            languagePack.teams,
            languagePack.success_update_season
          );
        } else {
          showNotification(
            "danger",
            languagePack.teams,
            languagePack.fail_update_season
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const tab1 = (
    <>
      <div className="df df-gap-15">
        <TFBInput
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          label={languagePack.team_name}
          style={{ backgroundColor: "white", width: "100%", marginBottom: 20 }}
        />
        <TFBColorPicker
          label={languagePack.team_color}
          noColor={teamColor === null}
          returnColor={(color) => setTeamColor(color)}
          initialColor={teamColor ?? ""}
          text={languagePack.select_color}
        />
      </div>
      <TFBCheckbox
        style={{ marginBottom: "15px" }}
        onChange={(e) => (e.target.checked ? setTeamColor(null) : "")}
        checked={teamColor === null}
        label={languagePack["no_color"]}
        mode="light"
      />
      <TFBSelect
        value={teamType}
        options={teamTypes}
        onChange={(selected) => setTeamType(selected)}
        label={languagePack.team_type}
        style={{ control: { backgroundColor: "white", marginBottom: 20 } }}
      />
      <TFBSelect
        value={coach}
        options={coaches}
        onChange={(selected) => setCoach(selected)}
        label={languagePack.team_coordinator}
        style={{ control: { backgroundColor: "white", marginBottom: 20 } }}
      />
      <TFBSelect
        value={activeSeason}
        options={seasons}
        onChange={(selected) => setActiveSeason(selected)}
        label={languagePack.active_season}
        style={{ control: { backgroundColor: "white", marginBottom: 20 } }}
      />
      {/* <TFBColorPicker
        label={languagePack.team_color}
        returnColor={color => setTeamColor(color)}
        initialColor={teamColor ?? ""}
        text={languagePack.select_color}
        buttonStyle={{ minWidth: 100, minHeight: 49 }}
      /> */}
    </>
  );

  const tab2 = (
    <>
      <TFBSelect
        value={selectedSeason}
        options={seasons}
        onChange={handleChangeSelectedSeason}
        label={languagePack.select_season}
        style={{ control: { backgroundColor: "white", marginBottom: 20 } }}
      />
      <div className="df-g20 tab-control-line tab-control-line-mw-500">
        <TFBDatePicker
          value={startDate}
          onChange={(value) => setStartDate(value)}
          label={languagePack.modify_start_date}
          style={{ backgroundColor: "white", width: "100%" }}
          onFocus={() => setEmptyDateUpdate(false)}
        />
        <TFBDatePicker
          value={endDate}
          onChange={(value) => setEndDate(value)}
          label={languagePack.modify_end_date}
          style={{ backgroundColor: "white", width: "100%" }}
          onFocus={() => setEmptyDateUpdate(false)}
        />
      </div>
      {moment(endDate) < moment(startDate) && (
        <div className="df-g20 tab-control-line">
          <div className="error-message">
            {languagePack.start_end_season_error}
          </div>
        </div>
      )}

      {emptyDateUpdate && (
        <div className="df-g20 tab-control-line">
          <div className="error-message">{languagePack.empty_date_error}</div>
        </div>
      )}

      {/* {endDate &&
        startDate &&
        !(moment(endDate) < moment(startDate)) &&
        moment(endDate).diff(moment(startDate), "days") <= 30 &&
        handleAlertSeasonDate(false)} */}

      <TFBCheckbox
        checked={isActiveSeason}
        disabled={selectedSeason?.value == currentActiveSeasonId}
        onChange={() => setIsActiveSeason(!isActiveSeason)}
        label={languagePack.set_season_as_active}
        className="tab-control-line"
        mode="light"
      />
      <div className="df-g20 tab-control-line tab-control-line-mw-500">
        <TFBButton
          color="darkGray"
          renderIcon={() => <TFBIcon name="cancel" />}
          className="tab-control-btn"
          onClick={() => {
            //reset selected season data to initial one
            handleChangeSelectedSeason(selectedSeason);
          }}
        >
          {languagePack.admin_cancel}
        </TFBButton>
        <TFBButton
          color="green"
          renderIcon={() => <TFBIcon name="check" />}
          className="tab-control-btn"
          disabled={
            moment(endDate) < moment(startDate) || !endDate || !startDate
          }
          onClick={updateSeason}
        >
          {languagePack.save_season}
        </TFBButton>
      </div>
      <div className="tab-control-line tab-control-line-mw-500">
        <TFBButton
          color="lightGray"
          renderIcon={() => <TFBIcon name="delete" color="gray" />}
          className="tab-control-btn"
          disabled={selectedSeason?.value == currentActiveSeasonId}
          onClick={openDeleteMessagePopup}
        >
          {languagePack.admin_delete}
        </TFBButton>
      </div>
      <div style={{ marginTop: 50 }}>
        {!addNewSeason ? (
          <div className="tab-control-line tab-control-line-mw-500">
            <TFBButton
              color="darkGray"
              renderIcon={() => <TFBIcon name="add" />}
              className="tab-control-btn"
              style={{ marginBottom: 0 }}
              onClick={openAddNewSeason}
            >
              {languagePack.create_season}
            </TFBButton>
          </div>
        ) : (
          <>
            <div className="df-g20 tab-control-line tab-control-line-mw-500">
              <TFBDatePicker
                value={newSeasonStartDate}
                onChange={(value) => setNewSeasonStartDate(value)}
                label={languagePack.select_start_date}
                placeholder={languagePack.season_start_date}
                style={{ backgroundColor: "white", width: "100%" }}
                onFocus={() => setEmptyDate(false)}
              />
              <TFBDatePicker
                value={newSeasonEndDate}
                onChange={(value) => setNewSeasonEndDate(value)}
                label={languagePack.select_end_date}
                placeholder={languagePack.season_end_date}
                style={{ backgroundColor: "white", width: "100%" }}
                onFocus={() => setEmptyDate(false)}
              />
            </div>
            {moment(newSeasonEndDate) < moment(newSeasonStartDate) && (
              <div className="df-g20 tab-control-line">
                <div className="error-message">
                  {languagePack.start_end_season_error}
                </div>
              </div>
            )}

            {/* {newSeasonEndDate &&
              newSeasonStartDate &&
              !(moment(newSeasonEndDate) < moment(newSeasonStartDate)) &&
              moment(newSeasonEndDate).diff(
                moment(newSeasonStartDate),
                "days"
              ) <= 30 &&
              handleAlertSeasonDate(true)} */}

            {emptyDate && (
              <div className="df-g20 tab-control-line">
                <div className="error-message">
                  {languagePack.empty_date_error}
                </div>
              </div>
            )}

            <TFBCheckbox
              checked={newSeasonIsActiveSeason}
              onChange={() =>
                setNewSeasonIsActiveSeason(!newSeasonIsActiveSeason)
              }
              label={languagePack.set_season_as_active}
              className="tab-control-line"
              mode="light"
            />
            <TFBCheckbox
              checked={migrateAll}
              onChange={() => {
                setMigrateAll(!migrateAll);
                setPlayersToMigrate(
                  playersToMigrate.map((p) => ({ ...p, checked: true }))
                );
              }}
              label={languagePack.migrate_all_active_players_to_new_season}
              className="tab-control-line"
              mode="light"
            />
            {!migrateAll && (
              <div className="tab-control-line df df-wrap df-gap-10">
                {playersToMigrate.map((p, i) => (
                  <TFBCheckbox
                    key={"player-id-" + i}
                    label={p.label}
                    onChange={(e) => handleCheckbox(i)}
                    checked={p.checked}
                    mode="light"
                  />
                ))}
              </div>
            )}
            <div className="df-g20 tab-control-line tab-control-line-mw-500">
              <TFBButton
                color="darkGray"
                renderIcon={() => <TFBIcon name="cancel" />}
                className="tab-control-btn"
                onClick={closeAddNewSeason}
              >
                {languagePack.admin_cancel}
              </TFBButton>
              <TFBButton
                color="green"
                renderIcon={() => <TFBIcon name="check" />}
                className="tab-control-btn"
                disabled={moment(newSeasonEndDate) < moment(newSeasonStartDate)}
                onClick={addSeason}
              >
                {languagePack.save_season}
              </TFBButton>
            </div>
          </>
        )}
      </div>
    </>
  );

  const tab3 = (
    <div style={{ minHeight: "250px" }}>
      <TFBCheckbox
        onChange={(e) => setRpeFromApp((state) => !state)}
        checked={rpeFromApp}
        label={languagePack.players_add_rpe_from_app}
        mode="light"
      />
    </div>
  );

  return (
    <>
      <TFBDialog
        className="pop-up-dialog-box prevent-select-text"
        title={languagePack.edit_team}
        open={isOpenPopup}
        style={{ display: openDeleteMessage ? "none" : "block" }}
        closePopup={closePopup}
        btnLeft={() => (
          <TFBButton
            color="darkGray"
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={closePopup}
          >
            {languagePack.admin_cancel}
          </TFBButton>
        )}
        btnRight={() => (
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="check" />}
            disabled={String(teamName).trim().length == 0}
            onClick={saveTeamData}
          >
            {languagePack.admin_save_changes}
          </TFBButton>
        )}
      >
        <TFBTabControl
          tabs={[
            languagePack.team_data,
            languagePack.season_management,
            languagePack.advanced_settings,
          ]}
          tabsContent={[tab1, tab2, tab3]}
          style={{ display: isLoading ? "none" : "block" }}
        />
        {isLoading && <CircularLoader />}
      </TFBDialog>

      {/* ####################### DELETE QUESTION ################ */}
      <TFBDialog
        className="pop-up-dialog-box prevent-select-text"
        title={languagePack.delete_season}
        open={openDeleteMessage}
        closePopup={closeDeleteMessagePopup}
        btnLeft={() => (
          <TFBButton
            color="darkGray"
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={closeDeleteMessagePopup}
          >
            {languagePack.admin_cancel}
          </TFBButton>
        )}
        btnRight={() => (
          <TFBButton
            color="red"
            renderIcon={() => <TFBIcon name="delete" />}
            onClick={deleteSeason}
          >
            {languagePack.admin_delete}
          </TFBButton>
        )}
      >
        <div className="pop-up-dialog-delete-text">
          {languagePack.delete_season_message}
        </div>
      </TFBDialog>
    </>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(EditTeam);
