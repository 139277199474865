import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { showNotification } from "../../../components/customHooks/useAxiosPost";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import { countries } from "../../../utils/countries";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";
import "./AcceptRequest.scss";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import { Checkbox } from "semantic-ui-react";
import moment from "moment";

const positions_dropdown = [
  { key: "GK", value: "Goalkeeper", text: "Portar", label: "Portar" },
  { key: "DEF", value: "Defender", text: "Fundas", label: "Fundas" },
  { key: "MID", value: "Midfielder", text: "Mijlocas", label: "Mijlocas" },
  { key: "ATT", value: "Forward", text: "Atacant", label: "Atacant" },
];

const main_foot_dropdown = [
  { key: "RT", value: "Right", text: "Picior drept", label: "Picior drept" },
  { key: "LT", value: "Left", text: "Picior stang", label: "Picior stang" },
  { key: "BOTH", value: "Both", text: "Ambele", label: "Ambele" },
];

const frfListDropdownU21 = [
  { key: 1, value: "A", text: "Lista A", label: "Lista A" },
  { key: 2, value: "B", text: "Lista B", label: "Lista B" },
  { key: 3, value: "0", text: "Nicio Lista", label: "Nicio Lista" },
];

const frfListDropdown = [
  { key: 1, value: "A", text: "Lista A", label: "Lista A" },
  { key: 2, value: "0", text: "Nicio Lista", label: "Nicio Lista" },
];

const frfListNoDob = [
  { key: 1, value: "0", text: "Nicio Lista", label: "Nicio Lista" },
];

function AcceptRequest({
  languagePack,
  id_user,
  id_club,
  id_request_to_accept,
  token,
  dialogOnClose,
  openDialog,
  cancelEnrolment,
  enrolmentDataToAccept,
  refreshRequests,
}) {
  const [countriesOptions, setCountriesOptions] = useState(countries);
  const [playerName, setPlayerName] = useState(
    `${enrolmentDataToAccept.player_name} ${enrolmentDataToAccept.player_surname}`
  );
  const [mainPosition, setMainPosition] = useState("");
  const [secondaryPosition, setSecondaryPosition] = useState("");
  const [mainFoot, setMainFoot] = useState("");
  const [nationality, setNationality] = useState("ro");
  const [secondNationality, setSecondNationality] = useState("");
  const [birthDate, setBirthDate] = useState(
    new Date(enrolmentDataToAccept.player_dob)
  );
  const [shirtNumber, setShirtNumber] = useState("");
  const [identityCard, setIdentityCard] = useState("");
  const [fifaId, setFifaId] = useState("");
  const [proLicense, setProLicense] = useState(false);
  const [teamOptions, setTeamOptions] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");

  const [playerFrfList, setPlayerFrfList] = useState("");
  const [isU21, setIsU21] = useState(true);
  const [isOnFrfList, setIsOnFrfList] = useState(false);

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    isU21Function();
  }, [birthDate]);

  const getTeams = () => {
    axios
      .get(
        `/club/get_teams_dropdown?id_club=${id_club}&all=0&id_user=${id_user}&token=${token}`
      )
      .then((res) => {
        let teamsOptions = res.data.map((team) => {
          return { ...team, label: team.text };
        });
        setTeamOptions(teamsOptions);
      })
      .catch((err) => console.log(err));
  };

  const renderBtn = (position, onClick) => {
    return (
      <TFBButton
        onClick={onClick}
        endIcon="true"
        color={position == "left" ? "darkGray" : "green"}
        renderIcon={() => (
          <TFBIcon name={position == "left" ? "cancel" : "check"} />
        )}
        disabled={
          position == "right" &&
          (playerName == "" ||
            nationality == "" ||
            (isOnFrfList == true && shirtNumber == "") ||
            selectedTeam == null ||
            selectedTeam == "")
        }
      >
        {position == "left" ? languagePack.Cancel : languagePack.accept}
      </TFBButton>
    );
  };

  const isOnFrfListFunction = () => {
    if (
      playerFrfList == "Nicio Lista" ||
      playerFrfList == "" ||
      playerFrfList == "0"
    ) {
      setIsOnFrfList(false);
    } else {
      setIsOnFrfList(true);
    }
  };

  //Calculate age of player
  function getAge(dateString) {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const isU21Function = () => {
    if (birthDate != null)
      getAge(new Date(birthDate)) < 22 ? setIsU21(true) : setIsU21(false);

    setPlayerFrfList("");
  };

  const frfListFilter = () => {
    if (isU21) {
      return frfListDropdownU21;
    } else {
      return frfListDropdown;
    }
  };

  // ======= full country name =======
  const getCountryName = (value) => {
    if (value && value != "noCountry") {
      const selected_country = countries.find((item) => item.value === value);
      return selected_country.text;
    } else return "";
  };

  // ============= connection with backend ==========================
  function acceptRequest() {
    const payload = {
      id_user: id_user,
      id_club: id_club,
      id_entity: id_request_to_accept,
      token: token,

      player: {
        player_name: playerName,
        player_dob: moment(birthDate).format("YYYY-MM-DD"),
        player_position: mainPosition.value,
        player_position_alternative: secondaryPosition.value,
        player_main_foot: mainFoot.value,
        birth_country: getCountryName(nationality),
        second_country: getCountryName(secondNationality),
        shirt_number: shirtNumber,
        frf_list: playerFrfList?.value == "" ? "0" : playerFrfList?.value,
        cnp: identityCard,
        fifa_id: fifaId,
        has_professional_license: { true: 1, false: 0 }[proLicense],
      },
      teams_to_enroll: selectedTeam,
    };

    console.log("payload", payload);

    axios.post("/club/accept_enrolment_request", payload).then((resp) => {
      if (resp.data.success == 1) {
        showNotification(
          "success",
          languagePack.enrolment_request,
          languagePack.success_enrolment_request
        );
        refreshRequests();
        dialogOnClose();
      }

      if (resp.data.success == 0) {
        showNotification(
          "danger",
          languagePack.enrolment_request,
          languagePack.failed_enrolment_request
        );
      }
    });
  }

  return (
    <TFBDialog
      open={openDialog}
      closePopup={() => dialogOnClose()}
      title="Accept"
      // ======== Dialog footer buttons ========
      btnRight={() => renderBtn("right", () => acceptRequest())}
      btnLeft={() => renderBtn("left", cancelEnrolment())}
      // =======================================
      fullWidth={true}
      className="pop-up-dialog-box"
    >
      <div className="accept-request-popup-content">
        <TFBInput
          label={languagePack["PLAYER_NAME"]}
          placeholder={languagePack["PLAYER_NAME"]}
          value={playerName}
          fullWidth={true}
          onChange={(e) => setPlayerName(e.target.value)}
        />

        <div className="enrolment-accept-row">
          <TFBSelect
            placeholder={languagePack["POSITION"]}
            label={languagePack["POSITION"]}
            options={positions_dropdown}
            onChange={(selected) => setMainPosition(selected)}
          />
          <TFBSelect
            placeholder={languagePack["SECONDARY_POSITION"]}
            label={languagePack["SECONDARY_POSITION"]}
            options={positions_dropdown}
            onChange={(selected) => setSecondaryPosition(selected)}
          />
        </div>

        <div className="enrolment-accept-row-align">
          <div style={{ width: "100%" }}>
            <TFBSelect
              placeholder={languagePack["MAIN_FOOT"]}
              label={languagePack["MAIN_FOOT"]}
              options={main_foot_dropdown}
              onChange={(selected) => setMainFoot(selected)}
            />
          </div>
          <div style={{ width: "100%" }}>
            <TFBDropdown
              placeholder={languagePack["NATIONALITY"]}
              label={languagePack["NATIONALITY"]}
              value={nationality}
              color="lightGray"
              options={countriesOptions}
              selection
              name="birth_country"
              search
              onChange={(selected, value) => setNationality(value.value)}
            />
          </div>
        </div>

        <div className="enrolment-accept-row-align">
          <div style={{ width: "100%" }}>
            <TFBDropdown
              placeholder={languagePack["NATIONALITY"]}
              label={languagePack["NATIONALITY"]}
              value={secondNationality}
              color="lightGray"
              options={countriesOptions}
              selection
              name="second_country"
              search
              onChange={(selected, value) => setSecondNationality(value.value)}
            />
          </div>

          <div style={{ width: "100%" }}>
            <TFBDatePicker
              placeholder={languagePack["birth_date"]}
              label={languagePack["birth_date"]}
              style={{ width: "100%" }}
              emptyLabel={languagePack["birth_date"]}
              value={birthDate}
              onChange={(date) => {
                setBirthDate(date);
              }}
            />
          </div>
        </div>

        <div className="enrolment-accept-row">
          <TFBInput
            type="number"
            label={languagePack.shirt_number}
            placeholder={languagePack.shirt_number}
            fullWidth={true}
            onChange={(e) => setShirtNumber(e.target.value)}
          />
          <TFBSelect
            placeholder="Listă FRF"
            label="Listă FRF"
            options={birthDate == null ? frfListNoDob : frfListFilter()}
            value={playerFrfList}
            onChange={(selected) => {
              setPlayerFrfList(selected);
              isOnFrfListFunction();
            }}
          />
        </div>

        <div className="enrolment-accept-row">
          <TFBInput
            label={languagePack.identity_card_id}
            placeholder={languagePack.identity_card_id}
            style={{ width: "100%" }}
            onChange={(e) => setIdentityCard(e.target.value)}
          />
          <TFBInput
            label="Fifa ID"
            placeholder="Fifa ID"
            style={{ width: "100%" }}
            onChange={(e) => setFifaId(e.target.value)}
          />
        </div>

        <div style={{ marginTop: "20px" }}>
          <Checkbox
            label={languagePack.has_professional_license_issued}
            checked={proLicense}
            onChange={(e, data) => setProLicense(data.checked)}
          />
        </div>

        <div style={{ marginTop: "20px" }}>
          <TFBSelect
            placeholder={languagePack.select_team_teams}
            label={languagePack.select_team_teams}
            isMulti="true"
            options={teamOptions}
            onChange={(selected) => setSelectedTeam(selected)}
            value={selectedTeam}
            menuPlacement="auto"
            maxMenuHeight={130}
          />
        </div>
      </div>
    </TFBDialog>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(AcceptRequest);
