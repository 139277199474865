import React, { Component } from "react";
import "./FRFLists.scss";
import { Button, Icon, Checkbox } from "semantic-ui-react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import moment from "moment";
import Select from "react-select";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import { showNotification } from "../../../../components/customHooks/useAxiosPost";

class AddPlayerToList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [
        {
          label: "Lista A",
          value: "A",
        },
        {
          label: "Lista B",
          value: "B",
        },
      ],
      list_name: "",
      list_type: "",
      players: [],
      id_player: "",
      player_name: "",
      player_list: "",
      add_player_popup: false,
    };
  }

  componentDidMount = () => {};

  getPlayersForList = () => {
    axios
      .get(`club/get_players_for_list`, {
        params: {
          id_club: this.props.currentUser.id_club,
          token: this.props.token,
          id_season: "",
          list_type: this.state.list_type,
        },
      })
      .then((res) => {
        if (res?.data?.success == 0) {
        } else {
          this.setState({
            players: Array.isArray(res.data) ? res.data : [],
          });
        }
      })
      .catch((err) => console.log(err));
  };

  addPlayerToList = () => {
    if (this.state.player_list != "0") {
      this.setState({
        add_player_popup: true,
      });
    } else {
      this.addPlayerToListAxios();
    }
  };

  addPlayerToListAxios = () => {
    axios
      .post(`club/add_player_to_list`, {
        id_player: this.state.id_player,
        frf_list: this.state.list_type,
        id_club: this.props.currentUser.id_club,
      })
      .then((res) => {
        const list = this.state.list_type;
        if (res.data == 1) {
          this.props.refreshList();
          showNotification(
            "success",
            "Management club",
            "Jucatorul a fost adaugat cu succes in lista " + list + "!"
          );
        } else if (res.data == -1) {
          showNotification(
            "warning",
            "Management club",
            "Ai atins numarul maxim de jucatori pentru lista " + list + "."
          );
        } else {
          showNotification(
            "danger",
            "Management club",
            "S-a produs o eroare! Jucatorul nu a fost adaugat cu in lista " +
              list +
              ". Mai incearca o data!"
          );
        }
        if (this.state.add_player_popup) {
          this.setState({
            add_player_popup: false,
          });
        }
        this.props.closePopup();
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="add-match-container">
        <div className="add-match-header">
          {/* <div className="add-match-title">{languagePack["add_match"]}</div> */}
          <div className="add-match-title">{"Adauga jucator in lista"}</div>
          <Icon
            name="close"
            onClick={() => this.props.closePopup()}
            className="close-icon-popup"
          />
        </div>
        <div className="add-match-row">
          <TFBSelect
            placeholder="Selecteaza tipul de lista"
            options={this.state.lists}
            value={
              this.state.list_type != ""
                ? { value: this.state.list_type, label: this.state.list_name }
                : null
            }
            onChange={(selected) => {
              if (this.state.list_type != selected.value) {
                this.setState(
                  {
                    list_name: selected.label,
                    list_type: selected.value,
                    player_name: "",
                    id_player: "",
                    player_list: "",
                    players: [],
                  },
                  () => {
                    this.getPlayersForList();
                  }
                );
              }
            }}
          />
        </div>
        {this.state.list_type != "" && (
          <div className="add-match-row">
            <TFBSelect
              placeholder="Selecteaza jucatorul"
              options={this.state.players}
              value={
                this.state.id_player != ""
                  ? {
                      value: this.state.id_player,
                      label: this.state.player_name,
                    }
                  : null
              }
              onChange={(selected) => {
                this.setState({
                  player_name: selected.label,
                  id_player: selected.value,
                  player_list: selected.list,
                });
              }}
            />
          </div>
        )}
        <div className="add-match-buttons">
          <Button
            className="add-match-button cancel-button"
            onClick={() => this.props.closePopup()}
          >
            {languagePack["admin_cancel"]}
            <Icon name="cancel" className="white-icon" />
          </Button>
          <Button
            className="add-match-button"
            disabled={this.state.list_type == "" || this.state.id_player == ""}
            onClick={this.addPlayerToList}
          >
            {languagePack["admin_add"]}
            <Icon name="add" className="white-icon" />
          </Button>
        </div>
        <Dialog open={this.state.add_player_popup}>
          <DialogContent>
            <div className="display-payment-row">
              {"Atentie! Jucatorul va fi mutat din lista " +
                this.state.player_list +
                " in lista " +
                this.state.list_type +
                "! Vrei sa faci asta?"}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.addPlayerToListAxios}
              style={{ backgroundColor: "#00d948", color: "white" }}
            >
              {/* {languagePack["add_payment_button_overwrite"]} */}
              {"Da, vreau sa-l mut"}
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  add_player_popup: false,
                });
              }}
              color="var(--secondaryColor)"
            >
              {languagePack["add_payment_button_cancel"]}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(AddPlayerToList);
