import React, { useState } from "react";
import { useFormik } from "formik";
import { connect, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../redux/authRedux";
import { requestPassword, resetPassword } from "../../redux/authCrud";
import axios from "axios";
import { store } from "react-notifications-component";
import { showNotification } from "../components/customHooks/useAxiosPost";

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const { languagePack } = useSelector(
    ({ layoutService: { languagePack } }) => ({
      languagePack,
    })
  );
  const [isRequested, setIsRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: "AUTH.VALIDATION.INVALID_EMAIL",
        })
      )

      .min(
        3,
        intl.formatMessage(
          {
            id: "AUTH.VALIDATION.MIN_LENGTH",
          },
          {
            number: 3,
          }
        )
      )
      .max(
        50,
        intl.formatMessage(
          {
            id: "AUTH.VALIDATION.MAX_LENGTH",
          },
          {
            number: 50,
          }
        )
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const disableLoading = () => {
    setLoading(false);
  };

  function resetPassword(values) {
    const payload = new FormData();
    payload.append("email", values.email);
    payload.append("emailSubject", languagePack.password_reset);
    payload.append("emailHello", languagePack.hello);
    payload.append("emailText", languagePack.password_reset_email);
    axios
      .post("user/reset_password", payload)
      .then((res) => {
        if (res.data.success === 1) {
          //Good
          showNotification(
            "success",
            languagePack.password_reset,
            languagePack.FORM_CONTROL["AUTH.FORGOT.SUCCESS"]
          );

          setIsRequested(true);
        } else {
          showNotification(
            "danger",
            languagePack.reset_error_title,
            languagePack.auth_error_message
          );
          disableLoading();
        }
      })
      .catch((err) => console.log(err));
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // const formData = new FormData();
      // formData.append("email", values.email);
      // requestPassword(formData)
      //   .then(({data}) => {
      //     if(data.success === 1) {
      //       setIsRequested(true)
      //     } else {
      //       setStatus(
      //         intl.formatMessage({
      //           id: data.message,
      //         })
      //       );
      //     }
      //     setSubmitting(false);
      //   })
      //   .catch(() => {
      //     setIsRequested(false);
      //     setSubmitting(false);
      //     setStatus(
      //       intl.formatMessage(
      //         { id: "AUTH.VALIDATION.NOT_FOUND" },
      //         { name: values.email }
      //       )
      //     );
      //   });
      resetPassword(values);
    },
  });

  /*   const formik = useFormik({
      initialValues,
      validationSchema: LoginSchema,
      onSubmit: (values, { setStatus, setSubmitting }) => {
        openSmsPopup();
      },
    }); */

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-5">
            <div className="forg-title">
              <FormattedMessage id="AUTH.FORGOT.TITLE" />
            </div>
            <p
              style={{
                textAlign: "center",
                fontWeight: 800,
                fontSize: "1.1rem",
                fontFamily: "helvetica",
              }}
            >
              {intl.formatMessage({
                id: "AUTH.FORGOT.DESC",
              })}
            </p>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                {intl.formatMessage({
                  id: "AUTH.GENERAL.SUBMIT_BUTTON",
                })}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  {languagePack.Cancel}
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
      {/* {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              {intl.formatMessage({
                id: "AUTH.FORGOT.TITLE",
              })}
            </h3>
            <div className="text-muted font-weight-bold">
              {intl.formatMessage({
                id: "AUTH.FORGOT.DESC",
              })}
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                {intl.formatMessage({
                  id: "AUTH.GENERAL.SUBMIT_BUTTON",
                })}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  {languagePack.Cancel}
                </button>
              </Link>
            </div>
          </form>
        </div>
      )} */}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
