import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import "./FRFLists.scss";
import { logout } from "./../../../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon, Flag, Button, Dropdown } from "semantic-ui-react";
import { store } from "react-notifications-component";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { showNotification } from "../../../../components/customHooks/useAxiosPost";

function ListComponent(props) {
  const { languagePack } = props;
  const [loading, setLoading] = useState(false);
  const [deleteItemDialog, setDeleteItemDialog] = useState(false);
  const [players, setPlayers] = useState([]);
  const [idPlayerDeleted, setIdPlayerDeleted] = useState("");
  const history = useHistory();

  useEffect(() => {
    getPlayersFromList();
  }, [props.type, props.refreshList]);

  const getPlayersFromList = () => {
    setPlayers([]);
    setLoading(false);
    axios
      .get(`club/get_players_from_list`, {
        params: {
          id_club: props.currentUser.id_club,
          token: props.token,
          id_season: "",
          list_type: props.type,
        },
      })
      .then((res) => {
        setPlayers(res.data);
        setLoading(true);
      })
      .catch((err) => console.log(err));
  };

  const deletePlayerFromList = () => {
    axios
      .post(`club/delete_player_from_list`, {
        id_player: idPlayerDeleted,
      })
      .then((res) => {
        if (res.data == 1) {
          setIdPlayerDeleted("");
          setDeleteItemDialog(false);
          getPlayersFromList();
          showNotification(
            "success",
            "Management club",
            "Jucatorul a fost sters cu succes din lista!"
          );
        } else {
          showNotification(
            "danger",
            "Management club",
            "S-a produs o eroare! Jucatorul nu a fost sters din lista. Mai incearca o data!"
          );
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {loading && players.length > 0 ? (
        <div className="frf-list-players">
          <div className="frf-list-row-container frf-list-row-title">
            <div className="crt">{"Nr. crt."}</div>
            <div className="player-name">
              {"Numele si prenumele jucatorului"}
            </div>
            <div className="player-dob">{"Data nasterii"}</div>
            <div className="player-number">{"Numar tricou"}</div>
          </div>
          {players.map((item, index) => {
            return (
              <div
                className="frf-list-row-container"
                style={{ backgroundColor: index % 2 == 0 ? "#ffffff" : "" }}
                key={"frf-list-player-" + index}
              >
                <div className="frf-list-mobile">
                  <div className="frf-list-row-crt">
                    <div className="crt">{"Nr. crt."}</div>
                    <div className="crt">{index + 1}</div>
                  </div>
                  <div className="frf-list-row-crt">
                    <div className="player-name">
                      {"Numele si prenumele jucatorului"}
                    </div>
                    <div
                      className="player-name"
                      onClick={() =>
                        history.push(`/club/player/${item.id_player}`)
                      }
                    >
                      {item.player_name}
                    </div>
                  </div>
                  <div className="frf-list-row-crt" style={{}}>
                    <div className="player-dob">{"Data nasterii"}</div>
                    <div className="player-dob">
                      {(item.player_dob != null) & (item.player_dob != "") ? (
                        <Moment format="DD.MM.YYYY">{item.player_dob}</Moment>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                  <div className="frf-list-row-crt">
                    <div className="player-number">{"Numar tricou"}</div>
                    <div className="player-number">
                      {item.shirt_number > 0 ? item.shirt_number : "#"}
                    </div>
                  </div>
                </div>

                <div className="frf-list-desktop">
                  <div className="crt">{index + 1}</div>
                  <div
                    className="player-name"
                    onClick={() =>
                      history.push(`/club/player/${item.id_player}`)
                    }
                  >
                    {item.player_name}
                  </div>
                  <div className="player-dob">
                    {(item.player_dob != null) & (item.player_dob != "") ? (
                      <Moment format="DD.MM.YYYY">{item.player_dob}</Moment>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="player-number">
                    {item.shirt_number > 0 ? item.shirt_number : "#"}
                  </div>
                  <div className="edit-section">
                    <Icon
                      name="trash"
                      // size="large"
                      className="edit-team-name-icon trash-hover"
                      onClick={() => {
                        setIdPlayerDeleted(item.id_player);
                        setDeleteItemDialog(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          {props.type == "A" &&
            Array.from(
              { length: 25 - players.length },
              (_, i) => i + players.length
            ).map((item, index) => {
              return (
                <div
                  className="frf-list-row-container"
                  style={{ backgroundColor: item % 2 == 0 ? "#ffffff" : "" }}
                  key={"frf-list-player-" + item}
                >
                  <div className="crt">{item + 1}</div>
                  <div className="player-name">{""}</div>
                  <div className="player-dob">{""}</div>
                  <div className="player-number">{""}</div>
                  <div className="edit-section"></div>
                </div>
              );
            })}
        </div>
      ) : (
        <div
          className="no-matches-placeholder"
          style={{ backgroundColor: "#fafafa" }}
        >
          {/* {languagePack["no_matches_for_selected_options"]} */}
          {"Nu sunt jucatori inscrisi pe lista."}
        </div>
      )}
      <Dialog open={deleteItemDialog}>
        <DialogContent>
          <div className="display-payment-row">
            {"Atentie! Jucatorul va fi sters din lista " +
              props.type +
              "! Vrei sa faci asta?"}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deletePlayerFromList}
            style={{ backgroundColor: "#00d948", color: "white" }}
          >
            {/* {languagePack["add_payment_button_overwrite"]} */}
            {"Da, vreau sa-l sterg"}
          </Button>
          <Button
            onClick={() => {
              setIdPlayerDeleted("");
              setDeleteItemDialog(false);
            }}
            color="var(--secondaryColor)"
          >
            {languagePack["add_payment_button_cancel"]}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(ListComponent);
