import React, { Component } from "react";
import "./CompetitionPage.scss";
import axios from "axios";
import Moment from "react-moment";
import delete_icon from "../../../../assets/icons/delete.png";
import edit_icon from "../../../../assets/icons/edit.png";
import { Dialog, DialogContent } from "@material-ui/core";
import EditMatchCompetition from "./EditMatchCompetition";
import { Button, Icon } from "semantic-ui-react";
import AddMatchForm from "../AddMatch/AddMatchForm";
import { showNotification } from "../../../../components/customHooks/useAxiosPost";
import { bindActionCreators } from "redux";
import { updateQuickSetup } from "../../../../utils/api";
import { connect } from "react-redux";
import { isTFB } from "../../../../utils/utilFunctions";

class CompetitionMatches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: [],
      editScorePopup: false,
      isEditingMatch: "",
      addMatchPopup: false,
      editedRound: null,
      newRoundName: "",
      wantToDeleteRound: "",
    };
  }

  componentDidMount = () => {};

  getMatches = () => {
    axios
      .get(
        `matches/get_competition_matches?id_competition=${this.props.id_competition}`
      )
      .then((res) => {
        this.setState({ matches: res.data });
      })
      .catch((err) => console.log(err));
  };

  editRoundName = () => {
    const payload = {
      id_competition: this.props.id_competition,
      old_name: this.state.editedRound,
      new_name: this.state.newRoundName,
    };
    axios
      .post("matches/edit_round", payload)
      .then(() => {
        showNotification(
          "success",
          this.props.languagePack["competitions_actions"],
          this.props.languagePack["manage_competitions_success_changes"]
        );
        this.props.getMatches();
        this.setState({ editedRound: null, newRoundName: "" });
      })
      .catch((err) => console.log(err));
  };

  deleteRound = () => {
    const payload = {
      id_competition: this.props.id_competition,
      round_name: this.state.editedRound,
    };
    axios
      .post("matches/delete_round", payload)
      .then(() => {
        showNotification(
          "success",
          this.props.languagePack["competitions_actions"],
          this.props.languagePack["manage_competitions_success_changes"]
        );
        this.props.getMatches();
        this.props.refreshGroupsResults();
        this.setState({ editedRound: null, newRoundName: "" });
      })
      .catch((err) => console.log(err));
  };

  deleteMatch = (id) => {
    const payload = {
      id_match: id,
      id_club: this.props.currentUser.id_club,
      is_tfb: isTFB(),
    };
    axios
      .post("matches/delete_match_2", payload)
      .then((res) => {
        if (res.data.success == 1) {
          showNotification(
            "success",
            this.props.languagePack["competitions_actions"],
            this.props.languagePack["match_deleted_success"]
          );

          let clubQuickSetup = res.data.club_quick_setup;
          this.props.updateQuickSetup({
            ...clubQuickSetup,
            isSetupOpen: false,
          });

          this.props.getMatches();
          this.props.refreshGroupsResults();
        }
      })
      .catch((err) => console.log(err));
  };

  changeLiveMatch = (id_match, live_state) => {
    const payload = {
      id_match: id_match,
      live_match: live_state,
      id_competition: this.props.id_competition,
    };
    axios
      .post("matches/update_match_live_state", payload)
      .then(() => {
        // store.addNotification({
        //   title: this.props.languagePack["competitions_actions"],
        //   message: this.props.languagePack["match_deleted_success"],
        //   type: "success",
        //   insert: "bottom",
        //   container: "bottom-right",
        //   animationIn: ["animate__animated", "animate__fadeIn"],
        //   animationOut: ["animate__animated", "animate__fadeOut"],
        //   dismiss: {
        //     duration: 2000,
        //   },
        // });
        this.props.getMatches();
        if (live_state == "0") {
          this.props.refreshGroupsResults();
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div>
        <div className="competition-page-matches-header">
          <div className="competition-page-matches-title">
            {languagePack["matches_schedule"]}
          </div>
          {this.props.hasAccessToEditCompetition && (
            <Button
              className="add-match-button"
              onClick={() => this.setState({ addMatchPopup: true })}
            >
              {languagePack["add_match"]}
              <Icon name="add" className="white-icon" />
            </Button>
          )}
        </div>
        <div className="competition-matches-container">
          {this.props.matches.map((item, index2) => {
            return (
              <div
                className="round-container"
                key={"comp-matches-key-" + index2}
              >
                <div className="round-title">
                  {languagePack["round"]} {item.round}
                  {this.props.hasAccessToEditCompetition && (
                    <img
                      src={edit_icon}
                      alt="edit"
                      style={{ marginLeft: 10, cursor: "pointer" }}
                      onClick={() => this.setState({ editedRound: item.round })}
                    />
                  )}
                </div>
                <div className="round-matches-list">
                  {item.matches.map((match, index) => {
                    return (
                      <div
                        className="round-match-row"
                        key={"matches-list-" + index}
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                        }}
                      >
                        <div className="match-date">
                          {match.match_date != "0000-00-00 00:00:00" ? (
                            <Moment format="DD.MM.YYYY, HH:mm">
                              {match.match_date}
                            </Moment>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div className="team-home">{match.team_home}</div>
                        <div className="score">
                          {match.team_home_goals} : {match.team_away_goals}
                        </div>
                        <div className="team-away">{match.team_away}</div>
                        {this.props.hasAccessToEditCompetition && (
                          <div className="match-options">
                            <img
                              src={edit_icon}
                              alt="edit"
                              onClick={() => {
                                this.setState({
                                  editScorePopup: true,
                                  isEditingMatch: match.id_match,
                                });
                              }}
                            />
                            <img
                              src={delete_icon}
                              alt="delete"
                              onClick={() => {
                                this.setState({
                                  wantToDeleteRound: match.id_match,
                                });
                              }}
                            />
                            <div className="live-match-container">
                              <div
                                className="live-match-icon"
                                style={{
                                  backgroundColor:
                                    match.live_match == "0" ? "grey" : "red",
                                }}
                                onClick={() => {
                                  match.live_match =
                                    match.live_match == "0" ? "1" : "0";
                                  this.changeLiveMatch(
                                    match.id_match,
                                    match.live_match
                                  );
                                }}
                              ></div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}

          {/* ============ Add match popup ============== */}
          <EditMatchCompetition
            key={"edit-match-form-" + this.state.isEditingMatch}
            closePopup={() =>
              this.setState({ editScorePopup: false, isEditingMatch: "" })
            }
            getMatches={() => this.props.getMatches()}
            idMatch={this.state.isEditingMatch}
            refreshGroupsResults={() => this.props.refreshGroupsResults()}
            openPopup={this.state.editScorePopup}
            type="past"
          />
          {/* ==========================================  */}

          {/* ============ Add match popup ============== */}
          <AddMatchForm
            key={"add-match-form-key-" + this.state.addMatchPopup}
            id_competition={this.props.id_competition}
            openPopup={this.state.addMatchPopup}
            closePopup={() => this.setState({ addMatchPopup: false })}
            getMatches={() => this.props.getMatches()}
            groups={this.props.groups}
            competition_type={this.props.competition_type}
            refreshGroupsResults={() => this.props.refreshGroupsResults()}
          />
          {/* ==========================================  */}

          <Dialog open={this.state.editedRound != null} maxWidth="xl">
            <DialogContent>
              <div className="edit-round-name-popup">
                <div className="edit-round-name-title">
                  {languagePack["edit_round"]}
                </div>
                <input
                  className="edit-team-input"
                  value={this.state.newRoundName}
                  onChange={(e) =>
                    this.setState({ newRoundName: e.target.value })
                  }
                  placeholder="Nume nou etapa"
                />
                <Button
                  className="delete-round-button"
                  onClick={this.deleteRound}
                >
                  {languagePack["delete_round"]}
                  <Icon name="trash" className="dark-icon" />
                </Button>
                <div className="edit-round-buttons">
                  <Button
                    className="edit-team-button"
                    style={{ backgroundColor: "#666666" }}
                    onClick={() =>
                      this.setState({ editedRound: null, newRoundName: "" })
                    }
                  >
                    {languagePack["admin_cancel"]}
                    <Icon name="cancel" className="white-icon" />
                  </Button>
                  <Button
                    className="edit-team-button"
                    style={{
                      backgroundColor: window.clubCustomization.primary_color,
                    }}
                    onClick={this.editRoundName}
                  >
                    {languagePack["admin_save"]}
                    <Icon name="add" className="white-icon" />
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
          <Dialog open={this.state.wantToDeleteRound !== ""}>
            <DialogContent className="news-options-delete-popup-container">
              <div className="news-options-delete-popup">
                <div className="header">
                  {languagePack["match_delete_popup_title"]}
                  <Icon
                    name="close"
                    onClick={() => this.setState({ wantToDeleteRound: "" })}
                  />
                </div>
                <div className="delete-message">
                  {languagePack["match_delete_popup_text"]}
                </div>
                <div className="delete-buttons">
                  <Button
                    className="news-options-button dark-background"
                    onClick={() => this.setState({ wantToDeleteRound: "" })}
                  >
                    {languagePack["admin_cancel"]}
                    <Icon name="close" className="news-options-button-icon" />
                  </Button>
                  <Button
                    className="news-options-button red-button"
                    onClick={() => {
                      this.deleteMatch(parseInt(this.state.wantToDeleteRound));
                      this.setState({ wantToDeleteRound: "" });
                    }}
                  >
                    {languagePack["admin_delete"]}
                    <Icon
                      name="trash alternate"
                      className="news-options-button-icon"
                    />
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateQuickSetup,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
  }),
  mapDispatchToProps
)(CompetitionMatches);
