import React, { Component } from "react";
import "./CompetitionInfoPage.scss";
import CompetitionRanking from "./CompetitionRanking";
import CompetitionMatches from "./CompetitionMatches";
import { Button, Icon, TextArea } from "semantic-ui-react";
import AddTeam from "../AddTeam/AddTeam";

import {
  Dialog,
  DialogContent,
  DialogActions,
  Switch,
} from "@material-ui/core/";

import axios from "axios";
import { connect } from "react-redux";
import KnockoutDiagram from "./KnockoutDiagram";
import AddSponsor from "./AddSponsor";
import { store } from "react-notifications-component";
import AddPrize from "./AddPrize";
import { Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { css } from "@emotion/css";
import { Redirect } from "react-router-dom";
import { baseUrl } from "../../../../utils/utilFunctions";
import PrizesPage from "./PrizesPage";
import { showNotification } from "../../../../components/customHooks/useAxiosPost";

class CompetitionInfoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      about: "",
      rules: "",
      phone: "",
      email: "",
      initial_data: {},
      addSponsorPopup: false,
      show_registration: false,
      show_prizes: false,
      show_groups: false,
      delete_prize_for_player: false,
      sponsors: [],
      id_prize_delete: "",
      prizes: [
        {
          player: "Petter Parker",
          number: "10",
          prize: "Tournament top scorer",
          photo: baseUrl("uploads/players/565.jpg"),
          id: "",
        },
        {
          player: "Brad Pitt",
          number: "7",
          prize: "Tournament best player",
          photo: "",
          id: "",
        },
        {
          player: "Jonh Telly",
          number: "1",
          prize: "Tournament best goalkeper",
          photo: "",
          id: "",
        },
      ],
      show_add_prize: false,
      editorState: EditorState.createEmpty(),
      hasAccessToCompetition: false,
      redirectToCompetitions: false,
    };
  }

  componentDidMount = () => {
    this.hasAccessToCompetition();
  };

  hasAccessToCompetition = () => {
    axios
      .get(
        `user/has_access_to_competition?id_competition=${this.props.match.params.id}&id_user=${this.props.currentUser.id_user}`
      )
      .then((res) => {
        if (res.data == 1) {
          this.setState({
            hasAccessToCompetition: true,
          });
          this.getDetails();
          this.getSponsors();
        } else {
          this.setState({
            redirectToCompetitions: true,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getDetails = () => {
    axios
      .get(
        `site/get_competition_app?id_competition=${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({
          description: res.data.description_competition,
          about: res.data.about_competition,
          rules: res.data.rules_competition,
          phone: res.data.competition_phone,
          email: res.data.competition_email,
          initial_data: res.data,
          show_registration: res.data.show_registration == "0" ? false : true,
          show_prizes: res.data.show_prizes == "0" ? false : true,
          show_groups: res.data.show_groups == "0" ? false : true,
        });
      })
      .catch((err) => console.log(err));
  };

  getSponsors = () => {
    axios
      .get(
        `site/get_competition_sponsors?id_competition=${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({
          sponsors: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

  getPrizes = () => {
    axios
      .get(
        `site/get_competition_prizes?id_competition=${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({
          prizes: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

  setInitialData = () => {
    this.setState({
      description: this.state.initial_data.description_competition,
      about: this.state.initial_data.about_competition,
      rules: this.state.initial_data.rules_competition,
      phone: this.state.initial_data.competition_phone,
      email: this.state.initial_data.competition_email,
    });
  };

  saveDetails = () => {
    const payload = {
      id_competition: this.props.match.params.id,
      description_competition: this.state.description,
      about_competition: this.state.about,
      rules_competition: this.state.rules,
      competition_phone: this.state.phone,
      competition_email: this.state.email,
    };
    axios
      .post(`site/update_competition/`, payload)
      .then((res) => {
        this.getDetails();
        showNotification(
          "success",
          this.props.languagePack["competitions_actions"],
          "Modificarile au fost efectuate cu succes!"
        );
      })
      .catch((err) => console.log(err));
  };

  deleteSponsor = (id) => {
    axios
      .post("site/delete_competition_sponsor", {
        id_competition_sponsor: id,
      })
      .then(() => {
        this.getSponsors();
        showNotification(
          "success",
          this.props.languagePack["fixtures_schedule"],
          this.props.languagePack["payment_successfully_deleted"]
        );
      });
  };

  deletePrize = () => {
    axios
      .post("competition/delete_prize", {
        id_prize_delete: this.state.id_prize_delete,
      })
      .then(() => {
        this.setState({
          delete_prize_for_player: false,
          id_prize_delete: "",
        });
        this.getPrizes();
        showNotification(
          "success",
          this.props.languagePack["fixtures_schedule"],
          this.props.languagePack["payment_successfully_deleted"]
        );
      });
  };

  handleChangeArea = (e, { name, value }) => this.setState({ [name]: value });

  onChange = (editorState) => this.setState({ description: editorState });

  handleChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeShowRegistration = () => {
    this.setState({
      show_registration: !this.state.show_registration,
    });
    axios
      .post("site/update_registration", {
        id_competition: this.props.match.params.id,
        show_registration: {
          true: "1",
          false: "0",
        }[!this.state.show_registration],
      })
      .then(() => {
        this.getDetails();
      });
  };

  handleChangeShowPrizes = () => {
    this.setState({
      show_prizes: !this.state.show_prizes,
    });
    axios
      .post("site/update_prizes", {
        id_competition: this.props.match.params.id,
        show_prizes: {
          true: "1",
          false: "0",
        }[!this.state.show_prizes],
      })
      .then(() => {
        this.getDetails();
      });
  };

  handleChangeShowGroups = () => {
    this.setState({
      show_groups: !this.state.show_groups,
    });
    axios
      .post("site/update_show_groups", {
        id_competition: this.props.match.params.id,
        show_groups: {
          true: "1",
          false: "0",
        }[!this.state.show_groups],
      })
      .then(() => {
        this.getDetails();
      });
  };

  render() {
    if (this.state.redirectToCompetitions) {
      return <Redirect to="/club/competitions" />;
    }
    const { languagePack } = this.props;

    return (
      <>
        {this.state.hasAccessToCompetition && (
          <div className="row">
            <div className="col-lg-6" style={{ marginBottom: "25px" }}>
              <div className="card card-custom">
                <div className="info-container">
                  <div className="title-info-container">
                    {"Information about competition"}
                  </div>
                  <div className="description section-container">
                    <div className="title">
                      {"Add description/sub-title of the competition"}
                    </div>
                    <div className="content">
                      <TextArea
                        placeholder={""}
                        className="text-area"
                        value={this.state.description}
                        onChange={this.handleChangeArea}
                        name="description"
                      />
                    </div>
                  </div>
                  <div className="about section-container">
                    <div className="title">{"Add about competition"}</div>
                    <div className="content" style={{ marginTop: "15px" }}>
                      {/* <TextArea
                                        placeholder={""}
                                        className="text-area"
                                        value={this.state.about}
                                        onChange={this.handleChangeArea}
                                        name="about"
                                    /> */}
                      {window.innerHeight > 590 ? (
                        <CKEditor
                          editor={ClassicEditor}
                          config={{
                            toolbar: [
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "link",
                              "bulletedList",
                              "numberedList",
                              "blockQuote",
                              "|",
                              "undo",
                              "redo",
                            ],
                          }}
                          data={this.state.about}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            this.setState({
                              about: data,
                            });
                          }}
                        />
                      ) : (
                        <CKEditor
                          editor={ClassicEditor}
                          config={{
                            toolbar: [
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "link",
                              "-",
                              "bulletedList",
                              "numberedList",
                              "blockQuote",
                              "|",
                              "undo",
                              "redo",
                            ],
                            shouldNotGroupWhenFull: true,
                          }}
                          data={this.state.about}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            this.setState({
                              about: data,
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="rules section-container">
                    <div className="title">{"Add competition rules"}</div>
                    <div className="content" style={{ marginTop: "15px" }}>
                      {/* <TextArea
                                        placeholder={""}
                                        className="text-area"
                                        value={this.state.rules}
                                        onChange={this.handleChangeArea}
                                        name="rules"
                                    /> */}
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                            "|",
                            "undo",
                            "redo",
                          ],
                        }}
                        data={this.state.rules}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          this.setState({
                            rules: data,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="contact-details section-container">
                    <div className="title">
                      {"Add contact details of the competition"}
                    </div>
                    <div className="content">
                      <div className="contact-row">
                        <input
                          placeholder={"Phone number"}
                          className="add-contact-input"
                          name="phone"
                          value={this.state.phone}
                          onChange={this.handleChangeInput}
                        />
                        <input
                          placeholder={"E-mail"}
                          className="add-contact-input"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleChangeInput}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="info-final-buttons">
                    <Button
                      disabled={
                        this.state.initial_data.about_competition ==
                          this.state.about &&
                        this.state.initial_data.rules_competition ==
                          this.state.rules &&
                        this.state.initial_data.competition_phone ==
                          this.state.phone &&
                        this.state.initial_data.competition_email ==
                          this.state.email &&
                        this.state.initial_data.description_competition ==
                          this.state.description
                      }
                      className="info-button"
                      onClick={this.saveDetails}
                      style={{ width: "66%" }}
                    >
                      {languagePack["admin_save"]}
                      <Icon name="save" className="info-button-icon" />
                    </Button>
                    <Button
                      className="info-button dark-background"
                      style={{ width: "32%" }}
                      onClick={this.setInitialData}
                    >
                      {languagePack["admin_cancel_modif"]}
                      <Icon name="close" className="info-button-icon" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="card card-custom"
                style={{ marginBottom: "20px" }}
              >
                <div className="sponsors-container">
                  <div className="title-sponsors-container">
                    <div className="title">Sponsors</div>
                    <Button
                      className="add-sponsor-button"
                      onClick={() => this.setState({ addSponsorPopup: true })}
                    >
                      {/* {languagePack["add_competition"]} */}
                      <span
                        className={css`
                          @media only screen and (max-width: 590px) {
                            display: none;
                          }
                        `}
                      >
                        {"Add sponsor"}
                      </span>
                      <Icon name="add" className="add-button-white-icon" />
                    </Button>
                  </div>
                  <div className="sponsors-list">
                    {this.state.sponsors.map((elem, index) => {
                      return (
                        <div className="sponsor-elem-container">
                          <a
                            href={elem.sponsor_website}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="logo-container">
                              <img
                                src={baseUrl(
                                  `uploads/competition/sponsors_logos/${elem.id_competition_sponsor}.png`
                                )}
                              />
                            </div>
                          </a>
                          <a
                            href={elem.sponsor_website}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="sponsor-name">
                              {elem.sponsor_name}
                            </div>
                          </a>
                          <div className="modify-buttons">
                            <Icon
                              className="button trash-hover"
                              name="trash alternate"
                              onClick={() => {
                                this.deleteSponsor(elem.id_competition_sponsor);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                    {this.state.sponsors.length == 0 && (
                      <div>{"There are no sponsors added."}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="card card-custom">
                <div className="enable-container">
                  <div className="title-enable-container">
                    {"Enable/disable sections"}
                  </div>
                  <div
                    className="enable-registration-form"
                    style={{ justifyContent: "space-between", gap: "0" }}
                  >
                    <div className="title2">
                      {"Form for the registration of teams in competition"}
                    </div>
                    <Switch
                      checked={this.state.show_registration}
                      onChange={this.handleChangeShowRegistration}
                      color="primary"
                      style={{ color: "--var(primaryColor)" }}
                      name="anotherDatePayment"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                  <div
                    className="enable-registration-form"
                    style={{ justifyContent: "space-between", gap: "0" }}
                  >
                    <div className="title2">
                      {"Show groups of teams of the competition"}
                    </div>
                    <Switch
                      checked={this.state.show_groups}
                      onChange={this.handleChangeShowGroups}
                      color="primary"
                      name="anotherDatePayment"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>

                  {/* <div className="table-prizes" style={{ filter: !this.state.show_prizes ? "opacity(0.5)" : "opacity(1)" }}>
                                <div className="header-line">
                                <div className="col1 name">
                                Player name
                                </div>
                                <div className="col1 number">
                                Player number
                                </div>
                                <div className="col1 prize">
                                Prize title
                                </div>
                                <div className="col1 add-button">
                                <Button
                                className="info-button"
                                onClick={() => {
                                    this.setState({
                                        show_add_prize: true
                                    })
                                }}
                                style={{ width: "100%" }}
                                >
                                {"Add"}
                                <Icon name="add" className="info-button-icon" />
                                </Button>
                                    </div>
                                    </div>
                                    <div className="content-lines">
                                    {this.state.prizes.map((item, index) => {
                                        return <div className="line"
                                        style={{
                                            backgroundColor:
                                            index % 2 == 0 ? "#fafafa" : "white",
                                        }}
                                        id={index}>
                                        <div className="col1 name">
                                        <div className="photo">
                                        <Avatar
                                        src={item.photo}
                                        />
                                        </div>
                                        <div className="player-name">
                                        {item.player}
                                        </div>
                                        </div>
                                        <div className="col1 number">
                                        {item.number}
                                        </div>
                                        <div className="col1 prize">
                                        {item.prize}
                                        </div>
                                        <div className="col1 add-button">
                                        <Icon
                                        name="close"
                                        color="red"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => this.setState({
                                            delete_prize_for_player: true,
                                            id_prize_delete: item.id
                                        })}
                                                />
                                                </div>
                                                </div>
                                            })}
                                    {this.state.prizes.length == 0 && (
                                        <div className="no-line">
                                            {"There are no prizes added."}
                                        </div>
                                    )}
                                    </div>
                            </div> */}
                </div>
              </div>
            </div>
            <Dialog
              open={this.state.addSponsorPopup}
              // onClose={() => this.setState({ addTeamPopup: false })}
            >
              <DialogContent className="add-team-manual-wrapper">
                <AddSponsor
                  id_competition={this.props.match.params.id}
                  closePopup={() => this.setState({ addSponsorPopup: false })}
                  getSponsors={this.getSponsors}
                />
              </DialogContent>
            </Dialog>
            <Dialog
              open={this.state.show_add_prize}
              // onClose={() => this.setState({ show_add_prize: false })}
            >
              <DialogContent className="add-team-manual-wrapper">
                <AddPrize
                  closePopup={() => this.setState({ show_add_prize: false })}
                  getPrizes={this.getPrizes}
                />
              </DialogContent>
            </Dialog>
            <Dialog
              open={this.state.delete_prize_for_player}
              // onClose={() => this.setState({ delete_prize_for_player: false })}
            >
              <DialogContent className="add-team-manual-wrapper">
                <div>
                  {
                    "Are you sure that you want to delete this prize for player?"
                  }
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.deletePrize}
                  style={{ backgroundColor: "#00d948", color: "white" }}
                >
                  {languagePack["delete_payment_button_delete"]}
                </Button>
                <Button
                  onClick={() => {
                    this.setState({
                      delete_prize_for_player: false,
                      id_prize_delete: "",
                    });
                  }}
                  color="green"
                >
                  {languagePack["add_payment_button_cancel"]}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(CompetitionInfoPage);
