import React from "react";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import axios from "axios";
import { showNotification } from "../../../../components/customHooks/useAxiosPost";

export default function DeleteNotification({
  closePopup,
  openDeletePopup,
  languagePack,
  id_club,
  id_user,
  token,
  id_notification_to_delete,
  refreshMobileUsers,
}) {
  const renderBtn = (position, onClick) => {
    return (
      <TFBButton
        onClick={onClick}
        endIcon={true}
        color={position == "left" ? "darkGray" : "red"}
        renderIcon={() => (
          <TFBIcon name={position == "left" ? "cancel" : "delete"} />
        )}
        prettier-ignore
      >
        {position == "left" ? languagePack.Cancel : languagePack.Delete}
      </TFBButton>
    );
  };

  const deleteTag = () => {
    let payload = {
      id_club: id_club,
      id_user: id_user,
      token: token,
      id_entity: id_notification_to_delete,
    };

    axios
      .post("notification/delete_notification", payload, {
        params: { token: token },
      })
      .then((res) => {
        if (res.status == 200) {
          refreshMobileUsers();
          showNotification(
            "success",
            languagePack.notification_title_message,
            languagePack.notification_delete_sucess_message
          );
        } else {
          showNotification(
            "danger",
            languagePack.notification_title_message,
            languagePack.notification_delete_failed_message
          );
        }
        closePopup();
        return res;
      })
      .catch((err) => console.log(err));
  };

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      title={languagePack.delete_notification}
      open={openDeletePopup}
      closePopup={closePopup}
      btnRight={() => renderBtn("right", () => deleteTag())}
      btnLeft={() => renderBtn("left", () => closePopup())}
    >
      <div style={{ fontWeight: 700, fontSize: "15px", textAlign: "center" }}>
        {languagePack.delete_notification_message}
      </div>
    </TFBDialog>
  );
}
