import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import { logout } from "../../../utils/api";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import "./EditRequest.scss";
import axios from "axios";
import InputLabel from "./InputLabel";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { showNotification } from "../../../components/customHooks/useAxiosPost";

class EditRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      child_name: this.props.enrolmentDataToUpdate.player_name,
      child_surname: this.props.enrolmentDataToUpdate.player_surname,
      birth_date: new Date(this.props.enrolmentDataToUpdate.player_dob),
      adult_name: this.props.enrolmentDataToUpdate.parent_name,
      adult_surname: this.props.enrolmentDataToUpdate.parent_surname,
      selectedRelationship: this.props.enrolmentDataToUpdate.relationship,
      email: this.props.enrolmentDataToUpdate.parent_email,
      phoneNumber: this.props.enrolmentDataToUpdate.parent_phone_number,

      relativesOptions: [],

      wrongPhoneNumber: false,
      wrongEmail: false,
    };
  }

  componentDidMount() {
    this.getRelatives();
  }

  getRelatives = () => {
    axios
      .get(`parentsApp/get_relationships?id_club=${this.props.id_club}`)
      .then((res) =>
        this.setState({
          relativesOptions: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  //Check for phone number / email validation
  validateData = (fieldName, value) => {
    let isWrongEmail = this.state.wrongEmail;
    let isWrongNumber = this.state.wrongPhoneNumber;

    if (value) {
      switch (fieldName) {
        case "email":
          isWrongEmail = !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value);
          break;
        case "phoneNumber":
          if (value.indexOf(7) == 3) {
            isWrongNumber = !isValidPhoneNumber(value);
          } else {
            isWrongNumber = true;
          }

          break;
        default:
          break;
      }

      this.setState({
        wrongPhoneNumber: isWrongNumber,
        wrongEmail: isWrongEmail,
      });
    }
  };

  isDisabledButton = () => {
    if (
      this.state.child_name == "" ||
      this.state.child_surname == "" ||
      this.state.birth_date == null ||
      this.state.adult_name == "" ||
      this.state.adult_surname == "" ||
      this.state.selectedRelationship == null ||
      this.state.email == "" ||
      this.state.phoneNumber == "" ||
      this.state.wrongEmail ||
      this.state.wrongPhoneNumber
    )
      return true;

    return false;
  };

  handleDateChange = (value) => {
    this.setState({ birth_date: value });
  };

  editEnrolmentRequest = () => {
    const payload = {
      id_club: this.props.id_club,
      id_user: this.props.id_user,
      token: this.props.token,
      id_entity: this.props.id_request_to_update,
      player_surname: this.state.child_surname,
      player_name: this.state.child_name,
      player_dob: moment(this.state.birth_date).format("YYYY-MM-DD"),
      parent_surname: this.state.adult_surname,
      parent_name: this.state.adult_name,
      relationship: this.state.selectedRelationship,
      parent_email: this.state.email,
      parent_phone_number: this.state.phoneNumber,
    };

    axios
      .post("/club/update_enrolment_request", payload)
      .then((res) => {
        if (res.data.success == 1) {
          showNotification(
            "success",
            this.props.languagePack.enrolment_request,
            this.props.languagePack.edit_user_success
          );
          this.props.refreshRequests();
        } else {
          showNotification(
            "danger",
            this.props.languagePack.enrolment_request,
            this.props.languagePack.edit_user_danger
          );
        }
      })
      .catch((err) => console.log(err));

    this.props.closePopup();
  };

  render() {
    const { languagePack, closePopup, refreshRequests } = this.props;

    return (
      <div className="edit-request-container">
        <InputLabel
          title={languagePack.child_name}
          value={this.state.child_name}
          onChange={(e) => this.setState({ child_name: e.target.value })}
          placeholder={languagePack.child_name}
          className="add-input"
        />

        <InputLabel
          title={languagePack.child_surname}
          value={this.state.child_surname}
          onChange={(e) => this.setState({ child_surname: e.target.value })}
          placeholder={languagePack.child_surname}
          className="add-input"
        />

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="pick-birthday">
            <label>{languagePack.child_birthday}</label>
            <DatePicker
              value={this.state.birth_date}
              onChange={this.handleDateChange}
              emptyLabel={languagePack.birth_date}
              format="dd MMMM yyyy"
              inputVariant="outlined"
              className="birthday-date-input"
              style={{ width: "100%" }}
            />
          </div>
        </MuiPickersUtilsProvider>

        <InputLabel
          title={languagePack.adult_name}
          value={this.state.adult_name}
          onChange={(e) => this.setState({ adult_name: e.target.value })}
          placeholder={languagePack.adult_name}
          className="add-input"
        />

        <InputLabel
          title={languagePack.adult_surname}
          value={this.state.adult_surname}
          onChange={(e) => this.setState({ adult_surname: e.target.value })}
          placeholder={languagePack.adult_surname}
          className="add-input"
        />

        <div className="form-group">
          <div className="form-group-title">{languagePack.player_relative}</div>
          <div className="input-wrapper" style={{ width: "100%" }}>
            <TFBSelect
              placeholder={languagePack.player_relative}
              options={this.state.relativesOptions}
              value={this.state.selectedRelationship}
              onChange={(selected) =>
                this.setState({
                  selectedRelationship: selected,
                })
              }
            />
          </div>
        </div>

        <InputLabel
          title={languagePack.Email}
          value={this.state.email}
          onChange={(e) => {
            this.setState({ email: e.target.value });
            this.validateData("email", e.target.value);
          }}
          placeholder={languagePack.Email}
          className="add-input"
        />

        <div className="form-group">
          <div className="form-group-title">
            {languagePack.admin_contact_phone}
          </div>
          <div className="input-wrapper" style={{ width: "100%" }}>
            <PhoneInput
              initialValueFormat="national"
              defaultCountry="RO"
              placeholder={"Ex: 0712345678"}
              value={this.state.phoneNumber}
              onChange={(e) => {
                this.setState({ phoneNumber: e ?? "" }, () => {
                  this.validateData("phoneNumber", e);
                });
              }}
              className="phone-number-input"
            />
          </div>
        </div>

        {this.state.wrongPhoneNumber &&
          this.state.phoneNumber &&
          this.state.phoneNumber.length > 0 && (
            <div class="ui red message">{languagePack.wrong_phone_number}</div>
          )}
        {this.state.wrongEmail &&
          this.state.email &&
          this.state.email.length > 0 && (
            <div class="ui red message">{languagePack.wrong_email}</div>
          )}

        <div className="edit-request-footer-buttons">
          <TFBButton
            color="darkGray"
            endIcon={true}
            style={{ width: "100%" }}
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={() => {
              closePopup();
              refreshRequests();
            }}
          >
            {languagePack.admin_cancel}
          </TFBButton>
          <TFBButton
            color="green"
            endIcon={true}
            style={{ width: "100%" }}
            renderIcon={() => <TFBIcon name="check" />}
            onClick={this.editEnrolmentRequest}
            disabled={this.isDisabledButton()}
          >
            {languagePack.apply}
          </TFBButton>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(EditRequest);
