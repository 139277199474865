import React, { Component } from "react";
import "./AddMatchForm.scss";
import { Icon, Input, Checkbox } from "semantic-ui-react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { store } from "react-notifications-component";
import { showNotification } from "../../../../components/customHooks/useAxiosPost";
import { connect } from "react-redux";
import moment from "moment";

import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import ChangeMatchDurationPopup from "./ChangeMatchDurationPopup";

class EditMatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      match_date: null,
      location: "",
      locations: [],
      new_location: "",

      add_new_location: false,
      add_venue_to_mng: false,
      newR: "",
      newL: "",
      newRHalf: "",
      newLHalf: "",
      homeTeam: "",
      awayTeam: "",
      homeTeamId: "",
      awayTeamId: "",

      id_competition: "",

      minutes_per_time: "",
      new_minutes_per_time: "",
      changed_minutes_per_time: false,

      other_match_format: false,
      new_match_format: "",
      selected_match_format: "",
      match_formats: [],

      added_players: 0,

      open_edit_match_duration_popup: false,

      approximate_date: false,
    };
  }

  componentDidMount = () => {
    this.getLocations();
    this.getMatchFormats();
  };

  getMatchFormats = () => {
    axios
      .get(`matches/manual_match_formats`)
      .then((res) => {
        let matchFormats = [
          ...res.data.formats,
          { value: "0", label: this.props.languagePack.other_format },
        ];
        this.setState({ match_formats: matchFormats }, () =>
          this.getMatchBasicInfo()
        );
      })
      .catch((err) => console.log(err));
  };

  getMatchBasicInfo = () => {
    axios
      .get(`matches/get_manual_match_basic_info?id_match=${this.props.idMatch}`)
      .then((res) => {
        let matchLocation = {};

        if (res.data.id_venue > 0) {
          let locationsIds = [];
          this.state.locations.forEach((venue) => {
            locationsIds.push(venue.value);
          });

          let matchLocationId = locationsIds.find(
            (venue) => venue == res.data.id_venue
          );
          let indexOfVanue = locationsIds.indexOf(matchLocationId);
          matchLocation = this.state.locations[indexOfVanue];
        }

        let selectedMatchFormat;
        if (res.data.match_format == null) {
          selectedMatchFormat = "";
        } else if (res.data.match_format == 0) {
          selectedMatchFormat = {
            value: res.data.match_format,
            label: res.data.another_format_name,
          };
        } else {
          let findMatchFormat = this.state.match_formats.find(
            (match) => match.value == res.data?.match_format
          );

          selectedMatchFormat = findMatchFormat;
        }

        this.setState({
          match_date: res.data.match_date,
          location: res.data.id_venue > 0 ? matchLocation : "",
          newL: res.data.team_home_goals,
          newR: res.data.team_away_goals,
          newLHalf: res.data.home_team_goals_half_time,
          newRHalf: res.data.team_away_goals_half_time,
          homeTeam: res.data.home_team_name,
          awayTeam: res.data.away_team_name,
          homeTeamId: res.data.id_team_home,
          awayTeamId: res.data.id_team_away,
          approximate_date: { 1: true, 0: false }[res.data.approximate_date],
          id_competition: res.data.id_competition,
          minutes_per_time: res.data.minutes_per_time,
          selected_match_format: selectedMatchFormat,
          added_players: res.data.added_players,
        });
      })
      .catch((err) => console.log(err));
  };

  handleDateChange = (value) => {
    this.setState({ match_date: value });
  };

  getLocations = () => {
    axios
      .get(`site/get_locations?id_club=${this.props.currentUser.id_club}`)
      .then((res) => {
        let venuesArray = [
          { value: "0", label: this.props.languagePack.add_new_match_venue },
        ];
        res.data.forEach((venue) => {
          venuesArray.push({
            value: venue.id_venue,
            label: venue.venue_name,
          });
        });
        this.setState({ locations: venuesArray });
      })
      .catch((err) => console.log(err));
  };

  saveMatchChanges = () => {
    let payload = {
      id_match: this.props.idMatch,
      match_date: moment(this.state.match_date).format("YYYY-MM-DD HH:mm:ss"),
      approximate_date: { true: 1, false: 0 }[this.state.approximate_date],
      id_club: this.props.currentUser.id_club,
      team_home_goals: Number.isInteger(parseInt(this.state.newL))
        ? this.state.newL
        : null,
      team_away_goals: Number.isInteger(parseInt(this.state.newR))
        ? this.state.newR
        : null,
      home_team_goals_half_time: Number.isInteger(parseInt(this.state.newLHalf))
        ? this.state.newLHalf
        : null,
      team_away_goals_half_time: Number.isInteger(parseInt(this.state.newRHalf))
        ? this.state.newRHalf
        : null,
    };

    payload.minutes_per_time = this.state.changed_minutes_per_time
      ? this.state.new_minutes_per_time
      : this.state.minutes_per_time;

    payload.match_format = this.state.selected_match_format.value;
    payload.another_format_name = this.state.other_match_format
      ? this.state.new_match_format
      : null;

    if (this.state.add_new_location) {
      payload.venue_name = this.state.new_location;
      payload.add_venue_to_mng = { false: "0", true: "1" }[
        this.state.add_venue_to_mng
      ];
    } else {
      payload.id_venue = this.state.location.value;
    }

    axios.post("matches/update_match", payload).then(() => {
      showNotification(
        "success",
        this.props.languagePack["fixtures_schedule"],
        "Meciul a fost actualizat cu succes!"
      );
      this.props.getMatches();
      this.props.closePopup();
    });
  };

  handleChangeL = (e) => {
    this.setState({
      newL: e.target.value,
    });
  };

  handleChangeR = (e) => {
    this.setState({
      newR: e.target.value,
    });
  };

  handleChangeLHalf = (e) => {
    this.setState({
      newLHalf: e.target.value,
    });
  };

  handleChangeRHalf = (e) => {
    this.setState({
      newRHalf: e.target.value,
    });
  };

  handleChangeMinutesPerTime = (e) => {
    e.preventDefault();
    let numbers = /^\s*\d*\s*$/;
    if (
      e.target.value.match(numbers) &&
      e.target.value !== this.state.minutes_per_time
    ) {
      this.setState({
        changed_minutes_per_time: true,
        new_minutes_per_time: e.target.value,
      });
    } else
      this.setState({
        changed_minutes_per_time: false,
        new_minutes_per_time: e.target.value,
      });
  };

  handleChangeMatchFormat = (selectedFormat) => {
    if (selectedFormat.value == "0") {
      this.setState({
        selected_match_format: selectedFormat,
        other_match_format: true,
      });
      return;
    }
    this.setState({
      selected_match_format: selectedFormat,
      other_match_format: false,
    });
  };

  render() {
    const { languagePack } = this.props;

    return (
      <div className="add-match-container">
        <div className="add-match-header">
          <div className="add-match-title">{languagePack.edit_match}</div>
          <Icon
            name="close"
            onClick={() => this.props.closePopup()}
            className="close-icon-popup"
          />
        </div>
        <div className="add-match-row">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="date-input">
              <DateTimePicker
                value={this.state.match_date}
                onChange={this.handleDateChange}
                ampm={false}
                format="dd MMMM HH:mm"
                inputVariant="outlined"
                name="match_date"
                className="add-match-date-picker"
                emptyLabel={languagePack["match_date"]}
              />
              <Icon
                name="calendar alternate outline"
                className="calendar-icon"
                size="small"
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
        <div className="add-match-row">
          <div className="add-checkbox-input">
            <Checkbox
              label={languagePack.date_and_time_are_approximate}
              checked={this.state.approximate_date}
              onChange={(e, data) => {
                this.setState({
                  approximate_date: data.checked,
                });
              }}
            />
          </div>
        </div>

        {/* {this.state.id_competition == "-1" && ( */}
        <div className="add-match-row">
          <TFBInput
            type="number"
            label={languagePack.minutes_per_time}
            placeholder={languagePack.minutes_per_time}
            className="mintes-per-time-input"
            value={
              !this.state.changed_minutes_per_time
                ? this.state.minutes_per_time
                : this.state.new_minutes_per_time
            }
            onChange={(e) => this.handleChangeMinutesPerTime(e)}
          />
        </div>

        <div className="add-match-row">
          <TFBSelect
            placeholder="Format meci"
            label={languagePack.match_format}
            options={this.state.match_formats}
            value={this.state.selected_match_format}
            onChange={(selected) => this.handleChangeMatchFormat(selected)}
          />
        </div>

        {this.state.other_match_format && (
          <div className="add-match-row">
            <TFBInput
              placeholder={languagePack.add_match_format}
              label={languagePack.add_match_format}
              className="mintes-per-time-input"
              value={this.state.new_match_format}
              onChange={(e) => {
                this.setState({ new_match_format: e.target.value });
              }}
            />
          </div>
        )}
        {/* )} */}

        <div className="add-match-row">
          <div className="add-team-dropdown">
            <TFBSelect
              value={this.state.location}
              placeholder={languagePack["select_match_venue"]}
              label={languagePack["select_match_venue"]}
              options={this.state.locations}
              onChange={(selected) => {
                if (selected.value == "0") {
                  this.setState({ add_new_location: true });
                  this.setState({ location: selected });
                  this.setState({ new_location: "" });
                } else {
                  this.setState({ add_new_location: false });
                  this.setState({ location: selected });
                }
              }}
            />
          </div>
        </div>
        {this.state.add_new_location && (
          <>
            <div className="add-match-row">
              <input
                placeholder={languagePack["new_match_venue"]}
                className="round-input"
                value={this.state.new_location}
                onChange={(e) =>
                  this.setState({ new_location: e.target.value })
                }
              />
            </div>
            <div className="add-match-row">
              <div className="add-checkbox-input">
                <Checkbox
                  label={languagePack.add_location_to_field_management}
                  checked={this.state.add_venue_to_mng}
                  onChange={(e, data) => {
                    this.setState({
                      add_venue_to_mng: data.checked,
                    });
                  }}
                />
              </div>
            </div>
          </>
        )}
        {this.props.type == "past" && (
          <>
            <div className="match-result-type-label">
              {languagePack.half_time_result}
            </div>
            <div
              className="add-match-row"
              style={{ justifyContent: "space-around" }}
            >
              <div className="match-team">
                <div className="team-name">{this.state.homeTeam}</div>
              </div>
              <div className="match-editable-score">
                <Input
                  className="score-input"
                  value={this.state.newLHalf}
                  onChange={this.handleChangeLHalf}
                />
                :
                <Input
                  className="score-input"
                  value={this.state.newRHalf}
                  onChange={this.handleChangeRHalf}
                />
              </div>
              <div className="match-team">
                <div className="team-name">{this.state.awayTeam}</div>
              </div>
            </div>

            <div className="match-result-type-label">
              {languagePack.full_time_result}
            </div>
            <div
              className="add-match-row"
              style={{ justifyContent: "space-around" }}
            >
              <div className="match-team">
                <div className="team-name">{this.state.homeTeam}</div>
              </div>
              <div className="match-editable-score">
                <Input
                  className="score-input"
                  value={this.state.newL}
                  onChange={this.handleChangeL}
                />
                :
                <Input
                  className="score-input"
                  value={this.state.newR}
                  onChange={this.handleChangeR}
                />
              </div>
              <div className="match-team">
                <div className="team-name">{this.state.awayTeam}</div>
              </div>
            </div>
          </>
        )}
        <div className="add-match-buttons" style={{ gap: "15px" }}>
          <TFBButton
            color="darkGray"
            endIcon={true}
            renderIcon={() => <TFBIcon name="cancel" />}
            style={{ width: "50%" }}
            onClick={this.props.closePopup}
          >
            {languagePack["admin_cancel"]}
          </TFBButton>

          <TFBButton
            color="green"
            endIcon={true}
            renderIcon={() => <TFBIcon name="add" />}
            style={{ width: "50%" }}
            onClick={() =>
              this.state.changed_minutes_per_time &&
              Number(this.state.added_players) > 0
                ? this.setState({ open_edit_match_duration_popup: true })
                : this.saveMatchChanges()
            }
            disabled={
              (this.state.other_match_format &&
                (this.state.new_match_format == null ||
                  this.state.new_match_format == "")) ||
              (this.state.add_new_location &&
                (this.state.new_location == null ||
                  this.state.new_location == "")) ||
              (this.state.new_minutes_per_time === "" &&
                this.state.changed_minutes_per_time)
            }
          >
            {languagePack["admin_save"]}
          </TFBButton>
        </div>

        {this.state.open_edit_match_duration_popup &&
          this.state.new_minutes_per_time !== "" && (
            <ChangeMatchDurationPopup
              closePopup={() =>
                this.setState({ open_edit_match_duration_popup: false })
              }
              editMatch={this.saveMatchChanges}
            />
          )}
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(EditMatch);
