import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../redux/authRedux";
import { login } from "../../redux/authCrud";
import { authReduxFunctions } from "../../redux/functions/authReduxFunctions";
import axios from "axios";

import { useHistory } from "react-router-dom";
import CircularLoader from "../components/reusable/Loader";
import "./Auth.scss";
import { showNotification } from "../components/customHooks/useAxiosPost";

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl, redirectUrl } = props;
  const { languagePack } = useSelector(
    ({ layoutService: { languagePack } }) => ({
      languagePack,
    })
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [smsPopup, setSmsPopup] = useState(false);
  const [popupLang, setPopupLang] = useState("en");
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: "AUTH.VALIDATION.INVALID_EMAIL",
        })
      )
      .min(
        3,
        intl.formatMessage(
          {
            id: "AUTH.VALIDATION.MIN_LENGTH",
          },
          {
            min: 3,
            name: "Email",
          }
        )
      )
      .max(
        50,
        intl.formatMessage(
          {
            id: "AUTH.VALIDATION.MAX_LENGTH",
          },
          {
            max: 50,
            name: "Email",
          }
        )
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
  });

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      openSmsPopup();
    },
  });

  function openSmsPopup() {
    const payload = {
      email: formik.values.email,
      password: formik.values.password,
    };
    axios
      .post("user/generate_auth_code", payload)
      .then((res) => {
        if (res.data.success === 1) {
          checkCode();
          setPopupLang(res.data.lang);
        } else {
          showNotification(
            "danger",
            languagePack.auth_error_title,
            languagePack.auth_error_message
          );
          disableLoading();
        }
      })
      .catch((err) => console.log(err));
  }

  function finishLogin() {
    setTimeout(() => {
      login({
        email: formik.values.email,
        password: formik.values.password,
      })
        .then(
          ({
            data: { success, user, club_quick_setup, message, languagePack },
          }) => {
            disableLoading();
            if (success === 1 && user?.token) {
              authReduxFunctions(dispatch).login(user, languagePack);
              const clubQuickSetup = club_quick_setup;

              dispatch(
                auth.actions.updateQuickSetup({
                  ...clubQuickSetup,
                  isSetupOpen: false,
                })
              );
              if (redirectUrl) {
                history.push(redirectUrl);
              }
            } else {
              formik.setStatus(
                intl.formatMessage({
                  id: message || "AUTH.VALIDATION.INVALID_LOGIN",
                })
              );

              formik.setSubmitting(false);
            }
          }
        )
        .catch(() => {
          disableLoading();
          formik.setSubmitting(false);
          formik.setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
              locale: "ro",
            })
          );
        });
    }, 1000);
  }

  const checkCode = () => {
    const payload = {
      email: formik.values.email,
      input: "000000",
    };

    if (payload.input.length === 6) {
      axios.post("user/check_sms_code", payload).then((res) => {
        if (res.data.success == 1) {
          finishLogin();
        } else {
          showNotification(
            "danger",
            languagePack.auth_error_title,
            languagePack.auth_error_message
          );
          disableLoading();
        }
      });
    }
  };

  return (
    <div
      className="login-form login-signin"
      id="kt_login_signin_form"
      style={{ position: "relative" }}
    >
      {/* begin::Head */}
      <div className="text-center mb-5">
        <div className="testclass">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </div>
        <p
          style={{
            textAlign: "left",
            fontWeight: 800,
            fontSize: "1.1rem",
            fontFamily: "helvetica",
          }}
        >
          {languagePack.Enter_your_username_and_password}
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className={
          "form fv-plugins-bootstrap fv-plugins-framework" +
          (loading ? " disabled-login-action" : "")
        }
      >
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div
          className="form-group d-flex flex-wrap align-items-center justify-content-space-between"
          // style={{ paddingLeft: 20 }}
        >
          <button
            id="kt_login_signin_submit"
            type="submit"
            //disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3 button-login`}
          >
            <span style={{ fontFamily: "helvetica", fontWeight: 900 }}>
              Login
            </span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          <div
            className="forgot-pass"
            onClick={() => history.push("/auth/forgot-password")}
          >
            Reset password
          </div>
        </div>
      </form>

      <div>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "#ccc",
            margin: "20px 0",
          }}
        />

        <span className="no-account-wrapper">
          {languagePack.Dont_have_an_account_yet}{" "}
          <span className="sign-up" onClick={() => history.push("/register")}>
            {" "}
            {languagePack.Sign_Up_1}
          </span>
        </span>
      </div>
      {loading && (
        <div className="login-loader ">
          <CircularLoader />
        </div>
      )}
      {/*end::Form*/}

      {/* <Dialog
        open={smsPopup}
        onClose={() => {
          setSmsPopup(false);
          disableLoading();
        }}
        className="sms-popup-dialog-box"
      >
        <DialogContent>
          <SmsAuth
            onSubmit={() => finishLogin()}
            email={formik.values.email}
            lang={popupLang}
          />
        </DialogContent>
      </Dialog> */}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
