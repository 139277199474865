import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { showNotification } from "../../../components/customHooks/useAxiosPost";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import "./DeleteRequest.scss";

function DeleteRequest({
  languagePack,
  id_request_to_delete,
  id_user,
  id_club,
  token,
  closePopup,
  refreshRequests,
}) {
  function deleteRequest() {
    const payload = {
      id_entity: id_request_to_delete,
      id_user: id_user,
      id_club: id_club,
      token: token,
    };

    axios.post("/club/delete_enrolment_request", payload).then((resp) => {
      if (resp.data.success == 1) {
        showNotification(
          "success",
          languagePack.users_management,
          languagePack.delete_user_success
        );
        refreshRequests();
        closePopup();
      }

      if (resp.data.success == 0) {
        showNotification(
          "danger",
          languagePack.users_management,
          languagePack.delete_user_danger
        );
        closePopup();
      }
    });
  }

  return (
    <div className="delete-request-popup-container">
      <div className="delete-request-header">
        <div className="delete-request-title">
          {languagePack.delete_request}
        </div>

        <TFBIconButton
          name="x-small"
          color="darkGray"
          onClick={() => closePopup()}
        />
      </div>
      <div className="delete-request-text">
        {languagePack.delete_request_message}
      </div>
      <div className="delete-request-buttons">
        <TFBButton
          endIcon={true}
          color="darkGray"
          onClick={() => closePopup()}
          renderIcon={() => <TFBIcon name="cancel" />}
          style={{ width: "100%" }}
        >
          {languagePack["admin_cancel"]}
        </TFBButton>

        <TFBButton
          color="red"
          onClick={() => deleteRequest()}
          endIcon={true}
          renderIcon={() => <TFBIcon name="delete" />}
          style={{ width: "100%" }}
        >
          {languagePack.Delete}
        </TFBButton>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(DeleteRequest);
