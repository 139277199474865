import React from "react";
import { Modal, Header, Icon, Button } from "semantic-ui-react";
import "./Calendar.scss";
import axios from "axios";
import { store } from "react-notifications-component";
import { logout } from "./../../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { showNotification } from "../../customHooks/useAxiosPost";

class EventPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  editEvent = () => {
    axios.post("calendar/edit_event");
  };

  deleteEvent = () => {
    const event = {
      id_event: this.props.event.id,
    };
    axios
      .post("calendar/delete_event", event)
      .then(() => {
        this.props.refreshEvents();
        showNotification(
          "success",
          "Evenimente calendar",
          "Evenimentul a fost sters din programul echipei"
        );
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { event, language, languagePack } = this.props;

    return (
      <>
        <Header
          icon="calendar alternate"
          style={{ display: "flex" }}
          className="event-modal-header-container"
          content={
            <div className="event-modal-header">
              <div className="header-text">{languagePack["event_details"]}</div>
              <Icon
                name="close"
                onClick={() => this.props.closePopup()}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          }
        />
        <Modal.Content className="event-detail-content">
          <div>
            {" "}
            {event.description === "birthday" && (
              <>
                <div className="event-content-row">
                  <Icon name="birthday cake" size="large" />
                  <div>{event.name}</div>
                </div>
                <div className="event-content-row">
                  <div className="date-label">
                    {languagePack["injury_date"]}:
                  </div>
                  <div className="event-date">{event.date_start}</div>
                </div>
              </>
            )}
            {event.description === "match" && (
              <>
                <div className="event-content-row">
                  <Icon name="futbol" size="large" color="green" />
                  <b>{event.name}</b>
                </div>
                <div className="event-content-row">
                  <div className="date-label">
                    {languagePack["injury_date"]}:
                  </div>
                  <div className="event-date">{event.date_start}</div>
                </div>
                <div className="event-content-row">
                  <div className="hour-label">{languagePack["hour"]}:</div>
                  <div className="event-hour">{event.hour_start}</div>
                </div>
                <div className="event-content-row">
                  <div className="date-label">{languagePack["location"]}:</div>
                  <div className="event-date">{event.location}</div>
                </div>
              </>
            )}
            {event.description == "training" && (
              <>
                <div className="event-content-row">
                  {languagePack["team"]}:{" "}
                  <div className="event-date">{event.name}</div>
                </div>
                <div className="event-content-row">
                  <div className="date-label-event">
                    {languagePack["injury_date"]}:
                  </div>
                  <div className="event-date">{event.date_start}</div>
                </div>
                <div className="event-content-row">
                  <div className="hour-label">{languagePack["hour"]}:</div>
                  <div className="event-hour">
                    {event.hour_start + "-" + event.hour_end}
                  </div>
                </div>
                <div className="event-content-row" style={{ display: "block" }}>
                  {"Detalii"}:
                  <div className="event-desc-content">
                    {event.event_added_description}
                  </div>
                </div>
              </>
            )}
            {!["birthday", "match", "training"].includes(event.description) && (
              <>
                <div className="event-content-row">
                  {"Denumire"}: <div className="event-date">{event.name}</div>
                </div>
                <div className="event-content-row">
                  <div className="date-label-event">{"Data inceput"}:</div>
                  <div className="event-date">
                    {moment(event.full_date_start).format("DD.MM.YYYY, HH:mm")}
                  </div>
                </div>
                <div className="event-content-row">
                  <div className="hour-label">{"Data sfarsit"}:</div>
                  <div className="event-hour">
                    {moment(event.full_date_end).format("DD.MM.YYYY, HH:mm")}
                  </div>
                </div>
                <div className="event-content-row" style={{ display: "block" }}>
                  {languagePack["staff_description"]}:
                  <div className="event-desc-content">
                    {event.event_added_description}
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Content>
        {!["birthday", "match", "training"].includes(event.description) && (
          <div className="event-modal-buttons">
            <Button color="red" onClick={this.deleteEvent}>
              <Icon name="remove" style={{ color: "white" }} />{" "}
              {languagePack["admin_delete"]}
            </Button>
            {/* <Button color="green">
              <Icon name="checkmark" /> {languagePack['admin_save']}
            </Button> */}
          </div>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    language: layoutService.lang,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(EventPopup);
