import React, { useState, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { useLocation } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import styles from "./UserNotification.module.scss";
import "../UserList/UserList.scss";

import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import {
  showNotification,
  useAxiosPost,
} from "../../../components/customHooks/useAxiosPost";
import { TFBTableCell } from "../../../components/reusable/useStyles";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton.tsx";
import TFBTabControl from "../../../components/design-system/TabControl/TFBTabControl";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";

import CreateNotification from "./CreateNotification/CreateNotification";
import ViewNotification from "./ViewNotification/ViewNotification";
import DeleteNotification from "./DeleteNotification/DeleteNotification";
import useInfiniteScroll from "../../../components/customHooks/useInfiniteScroll";

const NotificationListMobile = (props) => {
  const location = useLocation();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      active_tab: 0,
      day: null,
      month_for: null,
      page: 1,
      limit: 20,
      has_more: true,
      notifications: [],

      openAddNotification: false,
      openViewNotification: false,
      openDeleteNotification: false,
      notificationToView: null,
      idNotificationToDelete: null,
    }
  );

  const { languagePack } = props;
  const { id_club, id_user, token } = props.currentUser;

  const {
    data: notifications,
    loading: isNotificationLoading,
    refetch: refetchMobileNotifications,
  } = useAxiosGet(
    `notification/get_notifications_sent_from_admin?id_club=${id_club}&id_user=${id_user}&token=${token}&page=${state.page}&limit=${state.limit}`,
    true
  );

  useEffect(() => {
    if (location.search.includes("from_functionality")) {
      updateState({ active_tab: 1 });
    }
  }, []);

  // useEffect(() => {
  //   console.log(notifications);
  //   if (notifications) {
  //     if (Array.isArray(notifications?.list)) {
  //       updateState({ notifications: notifications.list });
  //     }
  //   }
  // }, [notifications]);

  useEffect(() => {
    if (notifications?.list) {
      if (_.size(notifications?.list) > 0) {
        if (state.page == 1) {
          updateState({
            page: state.page + 1,
            notifications: notifications.list,
          });
        } else {
          updateState({
            page: state.page + 1,
            notifications: [...state.notifications, ...notifications.list],
          });
        }
        if (notifications?.settings) {
          const dayOptions = _.map(["first", "middle", "last"], (e) => ({
            value: e,
            label: languagePack?.send_notification_day_options?.[e],
          }));
          const monthForOptions = _.map(
            ["previous", "current", "next"],
            (e) => ({
              value: e,
              label: languagePack?.send_notification_for_options?.[e],
            })
          );

          updateState({
            day: notifications.settings?.mobile_app_arrear_reminder_day
              ? _.findIndex(
                  dayOptions,
                  (e) =>
                    e.value ==
                    notifications.settings?.mobile_app_arrear_reminder_day
                ) > -1
                ? dayOptions[
                    _.findIndex(
                      dayOptions,
                      (e) =>
                        e.value ==
                        notifications.settings?.mobile_app_arrear_reminder_day
                    )
                  ]
                : null
              : null,
            month_for: notifications.settings
              ?.mobile_app_arrear_reminder_month_for
              ? _.findIndex(
                  monthForOptions,
                  (e) =>
                    e.value ==
                    notifications.settings?.mobile_app_arrear_reminder_month_for
                ) > -1
                ? monthForOptions[
                    _.findIndex(
                      monthForOptions,
                      (e) =>
                        e.value ==
                        notifications.settings
                          ?.mobile_app_arrear_reminder_month_for
                    )
                  ]
                : null
              : null,
          });
        }
      } else {
        if (state.page == 1) {
          updateState({ notifications: [], has_more: false });
        } else {
          updateState({ has_more: false });
        }
      }
    }
  }, [notifications]);

  const { postData: saveRequest } = useAxiosPost("club/save_club_settings");

  const handleResponse = (response) => {
    if (response?.success == 1) {
      showNotification(
        "success",
        languagePack.notification_title_message,
        languagePack.success_save_notification_settings
      );
    } else {
      updateState({
        day: null,
        month_for: null,
      });

      showNotification(
        "danger",
        languagePack.notification_title_message,
        languagePack.fail_save_notification_settings
      );
    }
  };

  const saveSettings = () => {
    const payload = {
      id_club: id_club,
      mobile_app_arrear_reminder_day: state.day?.value ?? null,
      mobile_app_arrear_reminder_month_for: state.month_for?.value ?? null,
    };

    saveRequest(payload, handleResponse);
  };

  const { loaderRef } = useInfiniteScroll({
    refetch:
      !isNotificationLoading && state.has_more
        ? refetchMobileNotifications
        : () => {},
  });

  const tab1 = (
    <>
      {_.size(state.notifications) > 0 ? (
        <TableContainer>
          <Table faria-label="simple table" sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TFBTableCell>{languagePack.sent_notification}</TFBTableCell>
                <TFBTableCell align="center">
                  {languagePack.notification_send_by}
                </TFBTableCell>
                <TFBTableCell align="center">
                  {languagePack.date_hour_of_send}
                </TFBTableCell>
                <TFBTableCell align="right">
                  {languagePack.product_actions}
                </TFBTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.notifications.map((row, index) => (
                <TableRow
                  key={"notification-number-" + index}
                  sx={{
                    backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                  }}
                >
                  <TFBTableCell
                    style={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                    }}
                  >
                    {row.notification_title}
                  </TFBTableCell>
                  <TFBTableCell align="center">{row.full_name}</TFBTableCell>
                  <TFBTableCell align="center">
                    {moment(row.notification_date).format(
                      "DD.MM.YYYY HH:mm:ss"
                    )}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    <div
                      className="actions-btns-container"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <TFBIconButton
                        name="view"
                        color="darkGray"
                        onClick={() => {
                          updateState({
                            openViewNotification: true,
                            notificationToView: row,
                          });
                        }}
                      />

                      <TFBIconButton
                        onClick={() => {
                          updateState({
                            openDeleteNotification: true,
                            idNotificationToDelete: row.id_notification,
                          });
                        }}
                        name="delete"
                        color="darkGray"
                      />
                    </div>
                  </TFBTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        !isNotificationLoading && (
          <TFBPlaceholder text={languagePack.no_notification} />
        )
      )}
    </>
  );

  const tab2 = (
    <>
      <div className={`df-g20 ${styles.selectGroup}`}>
        <TFBSelect
          label={languagePack.send_notification_day}
          value={
            state.day?.value
              ? {
                  value: state.day?.value,
                  label:
                    languagePack.send_notification_day_options?.[
                      state.day?.value
                    ],
                }
              : null
          }
          options={_.map(["first", "middle", "last"], (e) => ({
            value: e,
            label: languagePack?.send_notification_day_options?.[e],
          }))}
          onChange={(selected) => updateState({ day: selected })}
          isClearable={true}
          style={{
            component: { width: "initial", minWidth: 250 },
            control: { backgroundColor: "white" },
          }}
        />
        <TFBSelect
          label={languagePack.send_notification_for}
          value={
            state.month_for?.value
              ? {
                  value: state.month_for?.value,
                  label:
                    languagePack.send_notification_for_options?.[
                      state.month_for?.value
                    ],
                }
              : null
          }
          options={_.map(["previous", "current", "next"], (e) => ({
            value: e,
            label: languagePack?.send_notification_for_options?.[e],
          }))}
          onChange={(selected) => updateState({ month_for: selected })}
          isClearable={true}
          style={{
            component: { width: "initial", minWidth: 250 },
            control: { backgroundColor: "white" },
          }}
        />
      </div>
      {state.day != null && state.month_for != null && (
        <div className={styles.notificationMessage}>
          <div className={styles.label}>
            {"Următorul mesaj va fi trimis la data de "}
            <span className={styles.boldText}>
              {
                {
                  first:
                    moment()
                      .locale("ro")
                      .date() >
                    moment()
                      .startOf("month")
                      .locale("ro")
                      .date()
                      ? moment()
                          .add(1, "M")
                          .startOf("month")
                          .locale("ro")
                          .format("DD MMMM")
                      : moment()
                          .startOf("month")
                          .locale("ro")
                          .format("DD MMMM"),
                  middle:
                    moment()
                      .locale("ro")
                      .date() >
                    moment()
                      .startOf("month")
                      .set("date", 15)
                      .locale("ro")
                      .date()
                      ? moment()
                          .add(1, "M")
                          .startOf("month")
                          .set("date", 15)
                          .locale("ro")
                          .format("DD MMMM")
                      : moment()
                          .startOf("month")
                          .set("date", 15)
                          .locale("ro")
                          .format("DD MMMM"),
                  last:
                    moment()
                      .locale("ro")
                      .date() >
                    moment()
                      .endOf("month")
                      .locale("ro")
                      .date()
                      ? moment()
                          .add(1, "M")
                          .endOf("month")
                          .locale("ro")
                          .format("DD MMMM")
                      : moment()
                          .endOf("month")
                          .locale("ro")
                          .format("DD MMMM"),
                }[state.day?.value]
              }
            </span>
            {" celor ce încă nu au achitat cotizația pentru luna "}
            <span className={styles.boldText}>
              {
                {
                  previous:
                    moment()
                      .locale("ro")
                      .date() >
                    {
                      first: 1,
                      middle: 15,
                      last: moment()
                        .endOf("month")
                        .locale("ro")
                        .date(),
                    }[state.day?.value]
                      ? moment()
                          .subtract(0, "months")
                          .locale("ro")
                          .format("MMMM")
                      : moment()
                          .subtract(1, "months")
                          .locale("ro")
                          .format("MMMM"),
                  current:
                    moment()
                      .locale("ro")
                      .date() >
                    {
                      first: 1,
                      middle: 15,
                      last: moment()
                        .endOf("month")
                        .locale("ro")
                        .date(),
                    }[state.day?.value]
                      ? moment()
                          .add(1, "M")
                          .locale("ro")
                          .format("MMMM")
                      : moment()
                          .locale("ro")
                          .format("MMMM"),
                  next:
                    moment()
                      .locale("ro")
                      .date() >
                    {
                      first: 1,
                      middle: 15,
                      last: moment()
                        .endOf("month")
                        .locale("ro")
                        .date(),
                    }[state.day?.value]
                      ? moment()
                          .add(2, "months")
                          .locale("ro")
                          .format("MMMM")
                      : moment()
                          .add(1, "months")
                          .locale("ro")
                          .format("MMMM"),
                }[state.month_for?.value]
              }
            </span>
            {":"}
          </div>
          <div className={styles.text}>
            {
              "“Vă aducem la cunoştinţă că aveţi de plată suma de 200 lei (valoare exemplu), însemnând  valoarea cotizaţiei pe luna "
            }
            <span>
              {
                {
                  previous:
                    moment()
                      .locale("ro")
                      .date() >
                    {
                      first: 1,
                      middle: 15,
                      last: moment()
                        .endOf("month")
                        .locale("ro")
                        .date(),
                    }[state.day?.value]
                      ? moment()
                          .subtract(0, "months")
                          .locale("ro")
                          .format("MMMM YYYY")
                      : moment()
                          .subtract(1, "months")
                          .locale("ro")
                          .format("MMMM YYYY"),
                  current:
                    moment()
                      .locale("ro")
                      .date() >
                    {
                      first: 1,
                      middle: 15,
                      last: moment()
                        .endOf("month")
                        .locale("ro")
                        .date(),
                    }[state.day?.value]
                      ? moment()
                          .add(1, "M")
                          .locale("ro")
                          .format("MMMM YYYY")
                      : moment()
                          .locale("ro")
                          .format("MMMM YYYY"),
                  next:
                    moment()
                      .locale("ro")
                      .date() >
                    {
                      first: 1,
                      middle: 15,
                      last: moment()
                        .endOf("month")
                        .locale("ro")
                        .date(),
                    }[state.day?.value]
                      ? moment()
                          .add(2, "months")
                          .locale("ro")
                          .format("MMMM YYYY")
                      : moment()
                          .add(1, "months")
                          .locale("ro")
                          .format("MMMM YYYY"),
                }[state.month_for?.value]
              }
            </span>
            {" pentru Ion Ion (valoare exemplu).”"}
          </div>
        </div>
      )}
      <TFBButton
        color="green"
        renderIcon={() => <TFBIcon name="check" />}
        onClick={saveSettings}
        disabled={
          (state.day == null && state.month_for != null) ||
          (state.day != null && state.month_for == null)
        }
      >
        {languagePack.admin_save_changes}
      </TFBButton>
    </>
  );

  // const handleViewNotification = (notification) => {
  //   updateState({
  //     openViewNotification: true,
  //     notificationToView: notification,
  //   });
  // };

  // const handleDeleteNotification = (id) => {
  //   updateState({
  //     openDeleteNotification: true,
  //     idNotificationToDelete: id,
  //   });
  // };

  const closeViewNotification = () => {
    updateState({
      openViewNotification: false,
      notificationToView: null,
    });
  };

  const closeDeleteNotification = () => {
    updateState({
      openDeleteNotification: false,
      idNotificationToDelete: null,
    });
  };

  return (
    <TFBCard>
      <TFBCardHeader title={props.languagePack.users_notifications}>
        {state.active_tab == 0 && (
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            onClick={() => updateState({ openAddNotification: true })}
          >
            {languagePack.create_notification}
          </TFBButton>
        )}
      </TFBCardHeader>

      <TFBCardBody style={{ marginTop: "50px" }}>
        <>
          <TFBTabControl
            tabs={[
              languagePack.send_custom_notification,
              languagePack.settings_automatic_notification,
            ]}
            tabsContent={[tab1, tab2]}
            tabsInfiniteScrollRefs={[loaderRef]}
            tabsLoading={[isNotificationLoading, false]}
            activeTab={state.active_tab}
            onChangeActiveTab={(i) => updateState({ active_tab: i })}
          />
        </>
      </TFBCardBody>

      {state.openAddNotification && (
        <CreateNotification
          openDialog={state.openAddNotification}
          closePopup={() => updateState({ openAddNotification: false })}
          refreshMobileUsers={() => {
            updateState({ notifications: [], page: 1, has_more: true });
          }}
        />
      )}

      {state.openViewNotification && (
        <ViewNotification
          openDialog={state.openViewNotification}
          closePopup={closeViewNotification}
          notificationDetail={state.notificationToView}
        />
      )}

      {state.openDeleteNotification && (
        <DeleteNotification
          openDeletePopup={state.openDeleteNotification}
          closePopup={closeDeleteNotification}
          languagePack={languagePack}
          id_notification_to_delete={state.idNotificationToDelete}
          id_club={props.currentUser.id_club}
          id_user={props.currentUser.id_user}
          token={props.currentUser.token}
          refreshMobileUsers={() => {
            updateState({ notifications: [], page: 1, has_more: true });
          }}
        />
      )}
    </TFBCard>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(NotificationListMobile);
